<template>
  <div
      class="mr-2 col-12"
  >
    <button
        type="button"
        :title="macros.fdv"
        @click="copyToClipboard(macros.fdv)"
        class="btn-icon-clipboard my-0 p-2"
        :class="btnMacrosClass"
        data-clipboard-text="air-baloon"
    >
      <div>
        <span><b>{{ convertTitleToVar(macros.fdv) }}</b></span>
        <span v-if="!hideDescription && !descriptionNextLine">{{ macros.description }}</span>
      </div>
      <span v-if="!hideDescription && descriptionNextLine" class="text-wrap">{{ macros.description }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "MacrosItem",
  props: {
    macros: {
      type: Object,
    },
    hideDescription: {
      type: Boolean,
      default () {
        return false
      },
    },
    descriptionNextLine: {
      type: Boolean,
      default () {
        return false
      },
    },
    btnMacrosClass: {
      type: String,
    },
    isForDocument: {
      type: Boolean,
    },
  },
  methods: {
    /**
     * Копирование макроса в буфер обмена по нажатию
     * @param item
     */
    copyToClipboard(word) {
      const el = document.createElement('textarea');
      el.value = this.convertTitleToVar(word);
      const parent = document.getElementsByClassName("btn-icon-clipboard")[0];
      parent.appendChild(el);
      el.select();
      document.execCommand('Copy');
      parent.removeChild(el);
      this.onCopy();
    },
    /**
     * Изменяет слово в макрос вида ${МАКРОС}
     * @param title
     * @returns {string}
     */
    convertTitleToVar(title) {
      const variable = `{${title}}`;
      return this.isForDocument ? `$${variable}` : variable;
    },
    onCopy() {
      this.$notify({
        type: 'success',
        title: 'Скопировано в буфер обмена'
      })
    },
  }
}
</script>


<style scoped>

</style>