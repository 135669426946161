<template>
  <b-row>
    <div class="p-2 rounded mr-3" :class="{
      'bg-white': active,
      'text-white': !active,
      // 'opacity-6': done,
    }">
      <template v-if="!done"> Шаг {{ step }}. </template>
      <template v-else> <b-icon-check /> </template>
    </div>
    <div class="p-2 rounded" :class="{
      'bg-white': active,
      'text-white': !active,
      // 'opacity-6': done,
    }">
      <slot />
    </div>

<!--    <div class="bg-white p-2 rounded">-->
<!--      <b-badge variant="primary" class="mr-1"> Шаг 1. </b-badge> Загрузите свои документы-->
<!--    </div>-->
  </b-row>
</template>

<script>
export default {
  name: "StepTitle",
  props: {
    step: {
      type: Number
    },
    active: {
      type: Boolean
    },
    done: {
      type: Boolean
    },
  }
}
</script>

<style scoped>

</style>