import Resource from "../resource";
import axios from "axios";

export default class ContractResource extends Resource {
	static async uploadFile(id, params) {
		const url = `${this.backendUrl}/${this.url}/${id}/upload`;
		const { data } = await axios.post(url, params);
		return data;
	}

	static async reject(id, params) {
		const url = `${this.backendUrl}/${this.url}/${id}/reject`;
		const { data } = axios.post(url, params);
		return data;
	}

	static async accept(id) {
		const url = `${this.backendUrl}/${this.url}/${id}/accept`;
		const { data } = await axios.post(url, {});
		return data;
	}


	static async printExcel(id) {
		const url = `${this.backendUrl}/${this.url}/${id}/print-excel`;
		const { data } = await axios.post(url, {});
		return data;
	}
}

ContractResource.url = 'contract';