<template>
  <div>
    <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
      <!--   В начало   -->
<!--      <li class="page-item prev-page mr&#45;&#45;2" :class="{disabled: value === 1}">-->
<!--        <a aria-label="Previous" @click="firstPage">-->
<!--          <span aria-hidden="true"><b-icon icon="chevron-double-left" aria-hidden="true" /></span>-->
<!--        </a>-->
<!--      </li>-->
      <!--   Пред. страница   -->
      <li class="page-item prev-page" :class="{disabled: value === 1}">
        <a class="page-link" aria-label="Previous" @click="firstPage">
          <span aria-hidden="true">
            <b-icon icon="chevron-left" aria-hidden="true" />
          </span>
        </a>
      </li>
      <li class="page-item" :class="{active: value === item}"
          :key="item"
          v-for="(item, index) in range(minPage, maxPage)">
<!--        <a class="page-link" @click="firstPage" v-if="minPage !== 1 && index === 0 && item === minPage">-->
<!--          1-->
<!--        </a>-->
<!--        <a class="page-link" @click="changePage(item)" v-if="(minPage !== 1 && index === 0 && item === minPage) && index <= defaultPagesToDisplay - 2">-->
<!--          {{item}}-->
<!--        </a>-->
<!--        <a class="page-link" @click="lastPage" v-if="index + 1 === defaultPagesToDisplay">-->
<!--          {{totalPages}}-->
<!--        </a>-->
        <a class="page-link" :key="index" @click="changePage(item)">
          {{item}}
        </a>
      </li>
      <!--   След. страница   -->
      <li class="page-item next-page" :class="{disabled: value === totalPages}">
        <a class="page-link" aria-label="Next" @click="lastPage">
          <span aria-hidden="true">
            <b-icon icon="chevron-right"  aria-hidden="true" />
          </span>
        </a>
      </li>
      <!--   В конец   -->
<!--      <li class="page-item next-page border-none ml&#45;&#45;2" :class="{disabled: value === totalPages}">-->
<!--        <a aria-label="Next" @click="lastPage">-->
<!--          <span aria-hidden="true"><b-icon icon="chevron-double-right" aria-hidden="true"></i></span>-->
<!--        </a>-->
<!--      </li>-->
    </ul>
    <div class="small text-center" :class="classFooter">
      Показывать <b class="btn-link pointer ml-1" @click="perPage = 15">15</b>
      | <b class="btn-link pointer" @click="perPage = 30">30</b>
      | <b class="btn-link pointer mr-1" @click="perPage = 100">100</b> записей
    </div>
<!--    <div class="small text-center">-->
<!--      Перейти в <b class="btn-link pointer ml-1" @click="firstPage">начало</b>-->
<!--      | <b class="btn-link pointer mr-1" @click="lastPage">конец</b>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: "base-pagination",
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage"
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage"
    },
    value: {
      type: Number,
      default: 1,
      description: "Pagination value"
    },
    size: {
      type: String,
      default: "",
      description: "Pagination size"
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)"
    },
    classFooter: {
      type: String,
      default: "",
      description: "Footer class"
    },
  },
  computed: {
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
      perPage: 15,
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.$emit("step", item, this.perPage);
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("step", this.value + 1);
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit("step", this.value - 1);
      }
    },
    firstPage() {
      this.$emit("step", 1, this.perPage);
    },
    lastPage() {
      this.$emit("step", this.totalPages, this.perPage);
    },
  },
  watch: {
    perPage() {
      this.$emit("step", 1, this.perPage);
    },
    total() {
      this.$emit("step", 1, this.perPage);
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
