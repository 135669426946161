<template>
  <div>
    <base-header type="default" class="pb-0 pb-md-4 mt--5 mt-md-0" />
    <div class="container-fluid mt-5 col-xl-10">
        <div class="card-header border-0 bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-white">Типы услуг</h3>
            </div>
          </div>
        </div>

        <div class="card table-responsive table-striped">
          <base-table
            class="table align-items-center table-flush"
            tbody-classes="list"
            :data="items"
            :thead-classes="'thead-light'"
          >
            <template slot="columns">
              <th>#</th>
              <th>Иконка</th>
              <th>Название</th>
              <th>Баллы</th>
              <th>Описание</th>
              <th></th>
            </template>

            <template slot-scope="{ row }">
              <td>{{ row.id }}</td>
              <td class="py-0"><i :class="`${row.icon} text-${row.color}`" class="display-4"/></td>
              <td class="text-wrap py-0">{{ row.fdv }}</td>
              <td class="py-0"><h2><b-badge variant="info" v-if="row.points">
                {{ row.points }} баллов
              </b-badge></h2></td>
              <td class="py-0">{{ row.description }}</td>
              <td class="py-0">
                <b-btn tag="a" variant="link" @click="editItem(row)" class="mr-2 text-primary p-2">
                  <b-icon-pencil-fill />
                </b-btn>
<!--                <b-btn tag="a" variant="outline-danger" @click="remove(row)">-->
<!--                  <b-icon icon="trash-fill" />-->
<!--                </b-btn>-->
              </td>
            </template>
          </base-table>
        </div>

<!--        <div class="card-footer d-flex justify-content-end">-->
<!--          <base-pagination></base-pagination>-->
<!--          <base-pagination total="1"></base-pagination>-->
<!--        </div>-->
    </div>
    <item-edit-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import ItemEditModal from "./ItemEditModal";

export default {
  name: "ServiceTypeList",
  components: {
    ItemEditModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.items = await this.$store.getters.getDict('dict_service_types');
    },
    async remove(item) {
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('itemEditModal');
    }
  },
};
</script>
<style>

</style>
