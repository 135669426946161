<template>
  <contract-list
      :loading="isLoading"
      :items="items"
      :items-pagination="itemsPagination"
      title="Одобренные"
      subtitle="Одобренные договоры"
  >
    <div class="text-center my-5" v-if="isLoading">
      <span class="loader" />
    </div>
    <contract-card v-for="item in items" :item="item" :key="item.id" v-else>
      <template #footer>
        <div class="pl-3 pb-3 mt--3 text-right">
          <a class="pointer text-sm mr-3" @click="setRegNumber(item)">
            <span class="dotted h5 font-weight-normal text-primary border-primary">
              Установить рег номер
            </span>
          </a>
        </div>
      </template>
    </contract-card>
  </contract-list>
</template>
<script>
import ContractResource from "../../../../resources/contract";
import ContractList from "./ContractList.vue";
import ContractCard from "../ContractCard.vue";
import EntryResource from "../../../../resources/entry";

export default {
  name: 'ContractListApproved',
  components: {ContractCard, ContractList},
  data() {
    return {
      items: [],
      itemsPagination: {},
    }
  },
  props: {
    reload: Number,
    filters: Object,
  },
  watch: {
    async reload() {
      await this.fetchData();
    },
  },
  async mounted() {
    this.$on('on-update', this.fetchData);
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filters.status = this.contractStatuses.approved;
      await this.fetch(ContractResource, this.filters);
    },
    async setRegNumber(contract) {
      this.isLoading = true;
      await EntryResource.setRegNumber(contract.entry.id);
      await this.fetchData();
      this.isLoading = false;
    }
  },
}
</script>