<template>
  <b-nav-item-dropdown class="dots-actions" right>
    <template #button-content>
      <slot name="button">
        <button class="btn btn-link text-dark mb-0">
          <b-icon-printer />
        </button>
      </slot>
    </template>
    <b-dropdown-item
        :disabled="!item.service.template_certificate_id"
        :href="`/entry/${item.entry.id}/print/${types.certificate}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer-fill /></b-col>
        <b-col align="left"><span>Сертификат</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        v-if="isManager"
        :disabled="!item.service.template_certificate_id"
        :href="`/entry/${item.entry.id}/print/${types.certificateDocx}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer-fill /></b-col>
        <b-col align="left"><span>Сертификат DOCX</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        v-if="isManager"
        @click="printExcel"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-table /></b-col>
        <b-col align="left"><span>Журнал</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        :href="`/entry/${item.entry.id}/print/contract`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-file-earmark-text-fill /></b-col>
        <b-col align="left"><span>Договор</span></b-col>
      </b-row>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import EntryResource from "../../../resources/entry";
import ContractResource from "../../../resources/contract";

export default {
  name: "ContractDocumentsPrint",
  data() {
    return {
      types: {
        certificate: 'certificate',
        certificateDocx: 'certificate-docx',
      }
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    async print(type) {
      const file = await EntryResource.print(this.item.id, type);
      window.open(file.url, '_blank');
    },
    async printExcel() {
      const url = await ContractResource.printExcel(this.item.id);
      window.open(url, '_blank');
    },
  },
}
</script>

<style>
.dots-actions .dropdown-toggle::after {
  content: none !important;
}
.dots-actions .dropdown-toggle {
  padding: 0px !important;
}
</style>
