<template>
  <div>
    <div class="card card-profile mt-4 p-3">
      <h6 class="heading-small text-muted" v-if="!hideTitle">
        Документы
        <b-btn size="sm" variant="default" class="float-right mt--1" @click="save">
          Сохранить
        </b-btn>
        <div size="sm" class="mt-1 small text-left text-transform-none" variant="secondary">
          Обратите внимание, что к загрузке допускаются только файлы с расширением .pdf, .jpg или .png
          не больше 10 Мб.
        </div>
      </h6>

      <a-label class="mt--2 mb--2" :label="'Паспорт'" required />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else>
        <b-form-file
            accept=".jpg, .png, .pdf"
            v-if="!documents.passport"
            v-model="documents.passport"
            placeholder="Выберите файл или перетащите его курсором..."
            drop-placeholder="Перетащите файл сюда..."
            size="sm"
        />
        <a-file
            @update-list="fetchData"
            class="document ml--2"
            :file="documents.passport"
            hide-icon text-size="sm"
            is-short-text
            @on-remove="documents.passport = null"
        />
      </template>

      <a-label class="mt-2 mb--2" :label="'Документ об образовании'" required />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else><b-form-file
          accept=".jpg, .png, .pdf"
          v-if="!documents.education"
          v-model="documents.education"
          placeholder="Выберите файл или перетащите его курсором..."
          drop-placeholder="Перетащите файл сюда..."
          size="sm"
      />
        <a-file
            @update-list="fetchData"
            class="document ml--2"
            :file="documents.education"
            hide-icon
            text-size="sm"
            is-short-text
            @on-remove="documents.education = null"
        />
      </template>

      <a-label class="mt-2 mb--2" :label="'СНИЛС'" required />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else>
        <b-form-file
            accept=".jpg, .png, .pdf"
            v-if="!documents.snils"
            v-model="documents.snils"
            placeholder="Выберите файл или перетащите его курсором..."
            drop-placeholder="Перетащите файл сюда..."
            size="sm"
        />
        <a-file
            @update-list="fetchData"
            class="document ml--2"
            :file="documents.snils"
            hide-icon text-size="sm"
            is-short-text
            @on-remove="documents.snils = null"
        />
      </template>

      <a-label class="mt-2 mb--2" :label="'Документ о смене фамилии'" />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else>
        <b-form-file
            accept=".jpg, .png, .pdf"
            v-if="!documents.surname"
            v-model="documents.surname"
            placeholder="Выберите файл или перетащите его курсором..."
            drop-placeholder="Перетащите файл сюда..."
            size="sm"
        />
        <a-file
            @update-list="fetchData"
            class="document ml--2"
            :file="documents.surname"
            hide-icon
            text-size="sm"
            is-short-text
            @on-remove="documents.surname = null"
        />
      </template>

      <a-label class="mt-2 mb--2" :label="'Другой документ'" />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else>
        <b-form-file
            accept=".jpg, .png, .pdf"
            v-if="!documents.other"
            v-model="documents.other"
            placeholder="Выберите файл или перетащите его курсором..."
            drop-placeholder="Перетащите файл сюда..."
            size="sm"
        />
        <a-file
            @update-list="fetchData"
            class="document ml--2"
            :file="documents.other"
            hide-icon
            text-size="sm"
            is-short-text
            @on-remove="documents.other = null"
        />
      </template>
    </div>
  </div>
</template>
<script>
import UserResource from "../../../resources/user";

export default {
  name: "UserDocuments",
  data() {
    return {
      isLoading: false,
      documents: {
        passport: null,
        education: null,
        snils: null,
        surname: null,
        other: null,
      }
    };
  },
  props: {
    reload: Number,
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    async reload() {
      await this.save();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const user = this.$store.getters.user;
      const files = await UserResource.fetchDocuments(user.id);
      Object.keys(this.documents).forEach(key => {
        if (files[key]) this.documents[key] = files[key];
      });
      this.isLoading = false;
      this.validateDocuments();
    },
    async save() {
      const userId = this.$store.getters.user.id;
      this.isLoading = true;
      let formData = new FormData();
      Object.keys(this.documents).forEach(key => {
        formData.append(key, this.documents[key]);
      });
      await UserResource.uploadDocuments(userId, formData);
      this.notifyUploaded();
      this.validateDocuments();
      this.isLoading = false;
    },
    validateDocuments() {
      if (!!this.documents.passport && !!this.documents.snils && !!this.documents.education)
        this.$emit('on-done');
    },
    notifyUploaded() {
      if (!this.hideTitle)
        this.$notify({
          type: "success",
          message: "Файлы успешно загружены!",
        });
    },
  },
}
</script>

<style>
.document .card-body {
  padding-left: .5rem;
  padding-top: .5rem;
  padding-bottom: 0;
}

.text-transform-none {
  text-transform: none;
}
</style>
