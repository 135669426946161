<template>
  <div class="row">

    <div class="col-xl-3 col-lg-6">
      <router-link to="entry">
        <stats-card
            title="Заявки"
            type="gradient-purple"
            :sub-title="$store.getters.user.entries_num"
            icon="ni ni-send"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
              <span class="text-success mr-1">
                <b-icon-arrow-up class="mr-1"/>
                {{ currentUser.future_points }}
              </span>
            <span class="text-nowrap">баллов повысится </span>
          </template>
        </stats-card>
      </router-link>
    </div>

    <div class="col-xl-3 col-lg-6">
      <router-link to="service">
        <stats-card
            title="Ближайшая услуга"
            type="gradient-primary"
            :sub-title="closestServiceBegda"
            :sub-title-class="`bg-gradient-primary text-white pl-1 pr-1`"
            icon="ni ni-calendar-grid-58"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
            <span class="text-nowrap"> {{ closestServiceTitle }} </span>
          </template>
        </stats-card>
      </router-link>
    </div>

      <div class="col-xl-3 col-lg-6">
        <router-link to="entry">
          <stats-card
              title="Осталось оплатить"
              :type="bgPayments"
              :sub-title="leftSum"
              icon="ni ni-money-coins"
              class="mb-4 mb-xl-0"
              :sub-title-class="`bg-${bgPayments} text-white pl-1 pr-1`"
          >
            <template slot="footer">
              <span class="text-nowrap">за текущие заявки</span>
            </template>
          </stats-card>
        </router-link>
      </div>

    <div class="col-xl-3 col-lg-6">
      <router-link to="service">
        <stats-card
            title="Баллы"
            type="gradient-info"
            :sub-title="`${currentUser.points ? currentUser.points : 0} баллов`"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
            <span class="text-nowrap">получено у нас</span>
          </template>
        </stats-card>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Widgets",
  computed: {
    leftSum() {
      return `${this.currentUser.left_sum ?? 0} ₽`;
    },
    bgPayments() {
      const color = this.currentUser.left_sum > 0 ?
          'danger' :
          'cyan';
      return `gradient-${color}`;
    },
    closestServiceBegda() {
      return this.currentUser.closestService ?
          this.currentUser.closestService.begda_formatted :
          'нет';
    },
    closestServiceTitle() {
      return this.currentUser.closestService ?
          `${this.currentUser.closestService.title.substring(0, 30)}...` :
          'нет ближайшей';
    }
  }
}
</script>

<style scoped>

</style>