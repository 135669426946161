<template>
  <div>
    <h6 class="heading-small text-muted" v-if="!hideTitle">
      Паспорт
      <b-btn size="sm" variant="default" class="float-right" @click="save">
        Сохранить
      </b-btn>
    </h6>
    <div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'Номер'" required class="mb--2" />
            <template v-if="isLoading">
              <b-skeleton class="h-25" />
            </template>
            <template v-else>
              <b-form-input :disabled="disabled" v-model="item.number" placeholder="4000 100200" v-inputmask="'9999 999999'" size="sm" />
            </template>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'Дата выдачи'" required class="mb--2" />
            <template v-if="isLoading">
              <b-skeleton class="h-25" />
            </template>
            <template v-else>
              <b-form-input :disabled="disabled" v-model="item.date" placeholder="ДД.ММ.ГГГГ" type="date" size="sm" />
            </template>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'Кем выдан'" required class="mb--2" />
            <template v-if="isLoading">
              <b-skeleton class="h-25" />
            </template>
            <template v-else>
              <b-form-input :disabled="disabled" v-model="item.issued_by" placeholder="ГУ МВД России по г. Санкт-Петербургу" size="sm" />
            </template>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IdentificationDocumentResource from "../../../../resources/identification-document";

export default {
  name: "ProfileForms",
  data() {
    return {
      isLoading: false,
      disabled: false,
      item: {
        user_id: null,
        dict_document_type_id: 1,
        number: null,
        date: null,
        issued_by: null,
      }
    }
  },
  props: {
    reload: Number,
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    async reload() {
      await this.save();
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const document = await IdentificationDocumentResource.fetch({
        user_id: this.$store.getters.user.id
      });
      if (document[0]) {
        this.item = document[0];
        this.disabled = true;
        this.$emit('on-done');
      }
      this.isLoading = false;
    },
    async save() {
      this.item.user_id = this.$store.getters.user.id;
      await this.validateAndSave(IdentificationDocumentResource, this.item, false);
      if (!this.hideTitle)
        this.$notify({
          type: "success",
          message: "Успешно сохранено!",
        });
      this.$emit('on-done');
    },
  }
}
</script>

<style scoped>

</style>