<template>
  <b-modal
      size="lg"
      :title="entry.service.title"
      title-class="text-lg p-2 text-center"
      id="entryEditModal"
      centered
      ref="entryEditModal"
      cancel-title="Закрыть"
      ok-title="Сохранить"
      @ok="saveItem"
      button-size="xl"
  >

    <b-form-group class="p-2" label="Секция" v-if="!entry.service.is_publication" label-class="pb-0">
      <l-select :options="sections" v-model="entry.section_id" :allow-empty="false" />
    </b-form-group>

    <b-row>
      <b-col lg="8" v-if="(!entry.is_paid) || isManager">
        <b-form-group class="p-2" label="Тип оплаты" label-class="pb-0">
          <b-form-radio-group
              id="btn-radios-1"
              v-model="entry.pay_type"
              :options="userPaymentTypes"
              name="radios-btn-default"
              buttons
          />
        </b-form-group>
      </b-col>

      <b-col class="ml-lg--5" v-if="isManager">
        <b-form-group class="p-2" label="Стоимость, руб." label-class="pb-0">
          <b-input v-model="entry.price" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group class="p-2" label="Тип участия" label-class="pb-0">
          <b-form-radio-group
              id="btn-radios-1"
              v-model="entry.is_extramural"
              :options="participationTypes"
              name="radios-btn-default"
              buttons
          />
        </b-form-group>
      </b-col>

      <b-col lg="8" v-if="isManager">
        <b-form-group class="p-2" label="Номер удостоверения" label-class="pb-0">
          <b-input v-model="entry.certificate_number" placeholder="780500064812" v-inputmask="999999999999" />
        </b-form-group>
      </b-col>
    </b-row>

<!--    <b-form-group class="ml-4 p-2" label="Нужен ли сертификат?">-->
<!--      <b-form-radio-group-->
<!--          v-model="entry.is_certificate_needed"-->
<!--          id="btn-radios-1"-->
<!--          :options="simpleAnswers"-->
<!--          name="radios-btn-default"-->
<!--          buttons-->
<!--      />-->
<!--    </b-form-group>-->

<!--    <b-form-group class="ml-4 p-2" label="Отправить сертификат по почте?">-->
<!--      <b-form-radio-group-->
<!--          v-model="entry.is_certificate_delivery"-->
<!--          id="btn-radios-1"-->
<!--          :options="simpleAnswers"-->
<!--          name="radios-btn-default"-->
<!--          buttons-->
<!--      />-->
<!--    </b-form-group>-->

  </b-modal>
</template>

<script>
import EntryResource from "../../../resources/entry";

export default {
  name: "EntryEditModal",
  data() {
    return {
      participationTypes: [
        { text: 'Очное', value: '0' },
        { text: 'Заочное', value: '1' },
      ],
      simpleAnswers: [
        { text: 'Да', value: '1' },
        { text: 'Нет', value: '0' },
      ],
    }
  },
  props: {
    entry: {
      type: Object,
    },
  },
  computed: {
    sections() {
      return this.entry.service.sections.map(item => ({
        ...item,
        label: item.title,
        value: item.id,
      }))
    }
  },
  methods: {
    async saveItem() {
      await EntryResource.save(this.entry);
      this.$notify({
        type: 'success',
        message: 'Заявка успешно сохранена!'
      })
      // this.$emit('on-update');
    },
  },
}
</script>

<style scoped>

</style>