<template>
  <a-dot-menu>
<!--    <b-dropdown-item-->
<!--        @click="$emit('edit', item)"-->
<!--    >-->
<!--      <b-row>-->
<!--        <b-col cols="1"><b-icon icon="pencil-square" /></b-col>-->
<!--        <b-col align="left"><span>Редактировать</span></b-col>-->
<!--      </b-row>-->
<!--    </b-dropdown-item>-->
    <b-dropdown-item :href="item.url" target="_blank">
      <b-row>
        <b-col cols="1"><b-icon-box-arrow-up-right scale="0.9"/></b-col>
        <b-col align="left"><span>На Joomla</span></b-col>
      </b-row>
    </b-dropdown-item>
<!--    <b-dropdown-divider />-->
    <b-dropdown-item
        @click="remove(item)"
        link-class="text-danger"
    >
      <b-row>
        <b-col cols="1"><b-icon icon="trash-fill" /></b-col>
        <b-col align="left"><span>Удалить</span></b-col>
      </b-row>
    </b-dropdown-item>
  </a-dot-menu>
</template>

<script>
import ServiceResource from "../../../resources/service";

export default {
  name: "ServiceActions",
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    async remove(item) {
      await this.deleteItem(ServiceResource, item.id);
      this.$emit('on-update');
    },
  }
}
</script>