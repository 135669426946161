<template>
  <b-card
      tag="article"
      class="mb-0 mt-0 bg-transparent border-0"
  >
    <b-card-header
        :header-bg-variant="bgHeader"
        header-text-variant="white"
        class="m-0 py-1 px-1 border-0 mb--4"
    >
      <b-row class="notification-header">
        <b-col>
          <h3 :class="headerClass" class="notification-header-margin">
            Новых уведомлений: {{ notifications.length }}
          </h3>
        </b-col>
        <b-col align="right">
          <b-btn size="sm" variant="default" class="mr-3" @click="readAll">
            Очистить
          </b-btn>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body class="px-2" :body-bg-variant="bgBody">
      <b-list-group v-if="!isLoading">
        <template v-for="notification in notifications">
          <b-list-group-item
              @click="readNotification(notification)"
              :class="`${notificationClass} text-${textColor}`"
              class="list-group-item-notification bg-transparent border-0"
              :key="notification.id"
          >
            <b-row class="text-xs">
              <b-col cols="1">
                <div
                    :class="`bg-${notification.color}`"
                    class="icon icon-shape text-white rounded-circle shadow mt-1"
                >
                  <i :class="notification.icon" />
                </div>
              </b-col>
              <b-col>
                {{ notification.created_at_formatted }}
                <div>
                  {{ notification.text_clear }}
                </div>
              </b-col>
            </b-row>
          </b-list-group-item>
        </template>

        <div class="mt-2" />

        <template v-for="notification in notificationsOld">
          <b-list-group-item
              @click="readNotification(notification)"
              :class="`mt-1 pt-0 mb-1 pb-0 opacity-4 text-${textColor}`"
              class="list-group-item-notification bg-transparent border-0"
              :key="notification.id"
          >
            <b-row class="text-xs">
              <b-col cols="1">
                <div class="icon icon-shape text-white rounded-circle shadow bg-gradient-dark mt-1">
                  <i :class="notification.icon" />
                </div>
              </b-col>
              <b-col>
                {{ $moment(notification.created_at).format("MMMM Do YYYY, HH:mm") }}
                <span v-html="notification.text" />
              </b-col>
            </b-row>
          </b-list-group-item>
        </template>
      </b-list-group>
      <b-list-group class="mb-3" v-else>

        <template v-for="item in [0, 1, 2]">
          <b-list-group-item
              :class="`pt-2 pb-2 mb-2`"
              class="list-group-item-notification bg-transparent border-0"
              :key="`load-${item}`"
          >
            <b-row>
              <b-col cols="1">
                <b-skeleton
                    type="avatar"
                    width="30px"
                    height="30px"
                />
              </b-col>
              <b-col>
                <b-skeleton width="70%"/>
                <b-skeleton width="80%"/>
                <b-skeleton width="90%"/>
                <b-skeleton width="70%"/>
              </b-col>
            </b-row>
          </b-list-group-item>
        </template>

      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import NotificationResource from "../../../resources/notification";

export default {
  name: "NotificationList",
  data() {
    return {
      notifications: [],
      notificationsOld: []
    };
  },
  props: {
    textColor: {
      type: String,
      default() {
        return 'white';
      }
    },
    bgHeader: {
      type: String,
      default() {
        return 'transparent';
      }
    },
    bgBody: {
      type: String,
      default() {
        return 'transparent';
      }
    },
    headerClass: {
      type: String,
      default() {
        return 'text-white mb-4';
      }
    },
    notificationClass: {
      type: String,
      default() {
        return 'pt-2 pb-2 mb-2';
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page) {
      if (page === undefined) page = 1;
      this.isLoading = true;
      if (!this.$store.getters.user.id)
        await this.$store.dispatch('me');
      const notifications = await NotificationResource.fetch({
        user_id: this.$store.getters.user.id
      }, page)
      this.notificationsOld = notifications.filter(elm => elm.checked);
      this.notifications = notifications.filter(elm => !elm.checked);
      this.notificationPagination = NotificationResource.dataPagination;
      this.isLoading = false;
    },
    async readNotification(item) {
      if (!item.checked)
        await NotificationResource.read(item.id);
      await this.$router.push(item.url);
    },
    async readAll() {
      await NotificationResource.readAll();
      this.newNotifications = [];
      await this.fetchData();
    }
  }
}
</script>

<style scoped>
.list-group-item-notification {
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
}
</style>