<template>
  <div class="pb-2">
    <h6 class="heading-small text-muted" v-if="!hideTitle">
      Согласие на обработку персональных данных
    </h6>
    <b-alert show variant="info" class="row">
      <b-col cols="1">
        <b-icon-info-circle scale="2" class="mt-3" />
      </b-col>
      <b-col>
        Скачайте согласие на обработку персональных данных, подпишите его
        и загрузите в форму
      </b-col>
    </b-alert>
    <a
        class="dotted pointer text-primary border-primary"
        @click="download"
        target="_blank"
    >
      Скачать согласие на обработку персональных данных
    </a>
  </div>
</template>

<script>
import UserResource from "../../../../resources/user";

export default {
  name: "ConsentDownload",
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async download() {
      const file = await UserResource.printConsent(this.$store.getters.user.id);
      if (file.url) window.open(file.url, '_blank');
    },
  }
}
</script>

<style scoped>

</style>