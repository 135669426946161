export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
      /**
       * Вызов сохранения из ресурса, вызов всплывающего уведомления
       * и заполнение массива ошибок
       * @param resourceModel Модель ресурса,
       * у которой будет использован метод save
       * для передачи на бэк
       * @param params
       * @param isOverwrite
       * @returns {Promise<void>}
       */
      async validateAndSave(
        resourceModel,
        params = undefined,
        isOverwrite = true
      ) {
          if (params === undefined) params = this.item;
          if (isOverwrite === undefined) isOverwrite = true;
          try {
              this.apiValidationErrors = [];
              if (isOverwrite)
                  this.item = await resourceModel.save(params)
              else
                  return await resourceModel.save(params)
              this.$notify({
                  type: "success",
                  message: "Успешно сохранено!",
              });
          } catch (error) {
              this.showErrorMessage(error);
              this.setApiValidation(error.response.data.errors)
          }
      },

      /**
       * Вывод ошибок
       * @param error
       */
      showErrorMessage(error) {
          if (error.response) {
              if (error.response.status === 422) {
                  this.$notify({
                      message: 'Заполните все обязательные поля!',
                      type: 'danger',
                  });
              } else {
                  this.$notify({
                      message: error.response.data.message,
                      type: 'danger',
                  });
              }
          } else {
              this.$notify({
                  message: 'Ошибка сервера',
                  type: 'danger',
              });
          }
      },
  }
};
