<template>
  <div class="row">
    <div class="col-xl-3 col-lg-6">
      <router-link to="entry">
        <stats-card
            title="Заявки"
            type="gradient-primary"
            :sub-title="statisticsCurrentMonth('entry')"
            icon="ni ni-send"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
                <span class="mr-1" :class="`text-${statisticsGrowthColor('entry')}`">
                  <b-icon :icon="statisticsGrowthIcon('entry')" />
                  {{ statisticsGrowthFormatted('entry') }}
                </span>
            <span class="text-nowrap">с прошлого месяца</span>
          </template>
        </stats-card>
      </router-link>
    </div>
    <div class="col-xl-3 col-lg-6">
      <router-link to="users">
        <stats-card
            title="Пользователи"
            type="gradient-pink"
            :sub-title="statisticsCurrentMonth('user')"
            icon="ni ni-single-02"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
            <span class="mr-1" :class="`text-${statisticsGrowthColor('user')}`">
              <b-icon :icon="statisticsGrowthIcon('user')" />
              {{ statisticsGrowthFormatted('user') }}
            </span>
            <span class="text-nowrap">с прошлого месяца</span>
          </template>
        </stats-card>
      </router-link>
    </div>
    <div class="col-xl-3 col-lg-6">
      <router-link to="entry">
        <stats-card
            title="Доходы"
            type="gradient-cyan"
            :sub-title="`${statisticsCurrentMonth('payment', true)} тыс.`"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
          <span class="mr-1" :class="`text-${statisticsGrowthColor('payment')}`">
            <b-icon :icon="statisticsGrowthIcon('payment')" />
            {{ statisticsGrowthFormatted('payment', true) }}
          </span>
            <span class="text-nowrap">с прошлого месяца</span>
          </template>
        </stats-card>
      </router-link>
    </div>
    <div class="col-xl-3 col-lg-6">
      <router-link to="/manager/publication">
        <stats-card
            title="Публикации"
            type="gradient-purple"
            :sub-title="statisticsCurrentMonth('publication')"
            icon="ni ni-align-center"
            class="mb-4 mb-xl-0"
        >
          <template slot="footer">
          <span class="mr-1" :class="`text-${statisticsGrowthColor('publication')}`">
            <b-icon :icon="statisticsGrowthIcon('publication')" />
            {{ statisticsGrowthFormatted('publication') }}
          </span>
            <span class="text-nowrap">с прошлого месяца</span>
          </template>
        </stats-card>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "WidgetsManager",
}
</script>

<style scoped>

</style>