<template>
  <div>
    <base-header type="gradient-indigo" class="pt-0 mt--3 mt-md-0 pt-md-5"/>
    <div class="container-fluid mt-5">

      <b-row>
        <b-btn
            variant="white"
            @click="createItem"
            class="ml-3 mb-4 opacity-4 cursor-pointer"
        >
          <b-icon-plus-lg class="mr-2" />
          <b-img rounded="circle" width="50" src="/img/no-avatar.png" />
        </b-btn>

        <b-card
            class="ml-3 mb-4 col-xl-10 mr-3 px-0"
        >
          <b-row>
            <b-col cols="1" class="my-2 mr--4 d-none d-xl-block">
              <b-icon-search font-scale="1.2"/>
            </b-col>
            <b-col xl="2">
              <a-label label="ФИО" label-class="mb-0" class="d-block d-xl-none" />
              <b-input v-model="filters.fio" placeholder="ФИО" lazy @change="fetchDataWithFilters" />
            </b-col>
            <b-col xl="2">
              <a-label label="Почта" label-class="mb-0" class="d-block d-xl-none" />
              <b-input v-model="filters.email" placeholder="example@mail" lazy @change="fetchDataWithFilters" />
            </b-col>
            <b-col xl="2">
              <a-label label="Телефон" label-class="mb-0" class="d-block d-xl-none" />
              <b-input
                  v-inputmask="'+7 (999) 999-99-99'"
                  placeholder="+7 (---) --- - -- - --"
                  v-model="filters.phone"
                  lazy
                  @change="fetchDataWithFilters"
              />
            </b-col>
            <b-col xl="2">
              <a-label label="Роль" label-class="mb-0" class="d-block d-xl-none" />
              <l-select
                  :options="roles"
                  placeholder-text="Роль"
                  v-model="filters.role"
                  @input="fetchDataWithFilters"
                  class="overflow-hidden mt-1"
              />
            </b-col>

            <b-col xl="3" class="mt-2 mt-xl-0">
              <b-btn v-b-toggle.collapse-filters variant="link" class="px-0 py-2 mt-1 text-dark">
                <span> Раскрыть фильтры </span>
              </b-btn>
            </b-col>
            <b-col xl="12">
              <b-collapse id="collapse-filters" class="mt-xl-3">
                <b-row>
                  <b-col xl="1">
                    <a-label label="ID" label-class="mb-0" class="d-block d-xl-none" />
                    <b-input v-model="filters.id" placeholder="ID" lazy @change="fetchDataWithFilters" />
                  </b-col>
                  <b-col xl="2">
                    <a-label label="Профиль" label-class="mb-0" class="d-block d-xl-none" />
                    <l-select
                        :options="dictProfiles"
                        placeholder-text="Профиль"
                        v-model="filters.profile"
                        @input="fetchDataWithFilters"
                        class="overflow-hidden mt-1"
                        multiple
                    />
                  </b-col>
                  <b-col xl="2">
                    <a-label label="Статус" label-class="mb-0" class="d-block d-xl-none" />
                    <l-select
                        :options="statuses"
                        placeholder-text="Статус"
                        v-model="filters.deleted"
                        @input="fetchDataWithFilters"
                        class="overflow-hidden mt-1"
                    />
                  </b-col>
                  <b-col xl="2">
                    <a-label label="Город" label-class="mb-0" class="d-block d-xl-none" />
                    <l-select
                        :options="cities"
                        placeholder-text="Город"
                        v-model="filters.is_spb"
                        @input="fetchDataWithFilters"
                        class="overflow-hidden mt-1"
                    />
                  </b-col>

                </b-row>
              </b-collapse>
            </b-col>
          </b-row>
        </b-card>
      </b-row>

      <card body-classes="px-0 pb-1 px-4" footer-classes="pb-2">
          <div class="table-users table-responsive table-striped">
            <base-table
                :loading="isLoading"
                class="table align-items-center table-flush"
                tbody-classes="list"
                :data="items"
                :thead-classes="'thead-light'"
                :skeleton-columns="[0, 1, 2, 3, 4, 5, 6]"
            >
              <template slot="columns">
                <th></th>
                <th class="col-2 text-center"> ФИО </th>
                <th></th>
                <th class="col-2"> Контакты </th>
<!--                <th> <span class="pl-5">Профили</span> </th>-->
                <th class="col-2"> Дата регистрации </th>
                <th></th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td>
<!--                  <b-img rounded="circle bg-size-cover" width="50" height="50" :src="avatarUrl(row)" />-->
                  <b-avatar
                      :badge="`${row.id}`"
                      variant="primary"
                      :class="{'opacity-6' : !!row.deleted_at}"
                      badge-variant="dark"
                      size="50"
                      :src="avatarUrl(row)"
                      :text="row.initials"
                  />
                </td>
                <td class="font-weight-bold text-wrap">
                  {{ row.fio }}
                  <br>

                  <!--        ОРГАНИЗАЦИЯ          -->
                  <b-badge variant="primary" class="mt-1 text-wrap">
                    <b-row>
                      <b-col cols="1">
                        <b-icon-bank class="mr-1 mb-1" font-scale="1.2" />
                      </b-col>
                      <b-col align="left" class="mt-1 ml--3 pr-1">
                        {{ row.organization }}
                      </b-col>
                    </b-row>
                  </b-badge> <br>

                  <!--        ДОЛЖНОСТЬ          -->
                  <b-badge variant="warning" class="mt-1 text-wrap">
                    <b-row>
                      <b-col cols="1">
                        <b-icon-person-fill class="mr-1" font-scale="1.2" />
                      </b-col>
                      <b-col align="left" class="ml--3 pr-1">
                        {{ row.job_title }}
                      </b-col>
                    </b-row>
                  </b-badge>

                </td>
                <td class="text-center">
                  <span> {{ row.service_count }}</span>
                  <i class="ni ni-archive-2 mx-1 pt-1" />
                </td>
                <td>
                  <b-icon-telephone class="mr-1" /> {{ row.phone }} <br>
                  <b-icon-envelope class="mr-1" /> {{ row.email }}
                </td>
                <!--        Профили        -->
<!--                <td class="d-flex flex-column">-->
<!--                  <b-badge-->
<!--                      pill-->
<!--                      class="m-1 w-fit-content"-->
<!--                      :class="`text-${profile.color}`"-->
<!--                      v-for="profile in row.profiles"-->
<!--                      :key="row.id + profile.id"-->
<!--                      size="xl"-->
<!--                  >-->
<!--                    {{ profile.fdv }}-->
<!--                  </b-badge>-->
<!--                </td>-->
                <td class="col-1 text-wrap text-muted">
                  {{ row.created_at_formatted }}
                </td>
                <td>
                  <b-btn
                      v-b-tooltip="'Вход под пользователем'"
                      variant="default"
                      @click="login(row)"
                      class="mr-2 px-3 pointer"
                      v-if="!row.deleted_at"
                  >
                    <b-icon icon="box-arrow-in-right" class="ml--1" scale="1.2" />
                  </b-btn>
                </td>
                <td>
                  <b-btn tag="a" variant="primary" @click="editItem(row)" class="mr-2 pointer">
                    <b-icon icon="pencil-square" />
                  </b-btn>
                  <b-btn tag="a" variant="danger" @click="remove(row)" v-if="!row.deleted_at">
                    <b-icon icon="trash-fill" />
                  </b-btn>
                  <b-btn tag="a" variant="warning" @click="restore(row)" v-else>
                    <b-icon-arrow-counterclockwise scale="1.2" />
                  </b-btn>
                </td>
              </template>
            </base-table>
          </div>

        <div class="card-footer d-flex justify-content-end" v-if="itemsPagination">
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
          />
        </div>
      </card>
    </div>
    <user-edit-modal :isNew="isNew" :user="selectedUser" @on-update="fetchData" />
  </div>
</template>
<script>
import UserResource from "../../../resources/user";
import UserEditModal from "./Edit/UserEditModal";

export default {
  name: "UsersList",
  components: {UserEditModal},
  data() {
    return {
      items: [],
      itemsPagination: null,
      selectedUser: null,
      isNew: null,
      roleUser: 2,
      user: {
        email: '',
        password: '',
      },
      filters: {
        id: null,
        fio: null,
        phone: null,
        email: null,
        deleted: null
      },
      roles: [
        { value: 1, label: 'Оператор' },
        { value: 2, label: 'Пользователь' },
        { value: 3, label: 'Гость' },
      ],
      statuses: [
        { value: null, label: 'Активны' },
        { value: true, label: 'Удалены' },
      ],
      cities: [
        { value: 1, label: 'Из СПб' },
        { value: 0, label: 'Другое' },
      ],
    };
  },
  computed: {
    dictProfiles() {
      const dict = this.$store.getters.getDict('dict_profiles');
      const profiles = dict.map((item) => ({
        ...item,
        label: item.fdv,
        value: item.id
      }));
      profiles.unshift({
        label: 'Не заполнены',
        value: 0,
      })
      return profiles;
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, perPage) {
      this.$bvModal.hide('userEditModal');
      this.fetch(UserResource, this.filters, page, perPage);
    },
    async remove(user) {
      await this.deleteItem(UserResource, user.id);
      await this.fetchData();
    },
    async restore(user) {
      user.deleted_at = null;
      await UserResource.save(user);
      await this.fetchData();
    },
    editItem(user) {
      this.selectedUser = user;
      this.isNew = false;
      this.$bvModal.show('userEditModal');
    },
    createItem() {
      this.selectedUser = {
        role: this.roleUser,
      };
      this.isNew = true;
      this.$bvModal.show('userEditModal');
    },
    /**
     * Вход под пользователем
     */
    async login(item) {
      await UserResource.login(item.id);
      this.$store.commit('SET_USER', item);
      // await this.$store.dispatch('me', true);
      await this.$router.push('/user/dashboard');
    },
    avatarUrl(item) {
      const avatarUrl = item.avatar_url;
      return item.avatar_id ? avatarUrl : null;
    }
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
.text-dashed {
  border-bottom: .2rem dashed rgba(0, 0, 0, 0.15) !important;
}
</style>
