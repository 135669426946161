<template>
  <b-card class="shadow">
    <h6 class="heading-small text-muted mb-4">
      Секции
    </h6>
    <div class="form-group">

      <b-row class="mb-4 pl-3">
        <b-form-textarea placeholder="Название секции" v-model="lastSection" class="col-9" />
        <b-btn variant="primary" class="ml-2" @click="addSection" :disabled="lastSection.length === 0"> + </b-btn>
      </b-row>

      <template v-if="sections.length > 0">
        <b-row v-for="(section, index) in sections" :key="index">
          <div class="col-11 ml-2">
            <b-row>
              <template v-if="isEditing(index)">
                <b-col cols="9" class="ml-1">
                  <b-form-input
                      v-model="section.title"
                      placeholder="Заголовок"
                  />
                </b-col>
              </template>
              <template v-else>
                <b-col cols="9" class="ml-2">
                  <b>Секция {{ index + 1 }}</b> {{ section.title }}
                </b-col>
              </template>

              <b-col cols="2" v-if="!item.id">
                <b-btn variant="danger" @click="removeSection(section)"> x </b-btn>
              </b-col>
              <template v-else>
                <template v-if="isEditing(index)">
                  <b-btn @click="stopEditing" variant="success">
                    <b-icon-check scale="1.5"/>
                  </b-btn>
                </template>
                <template v-else>
                  <a-dot-menu cols="2">
                    <b-dropdown-item @click="editSection(section)">
                      <b-row>
                        <b-col cols="1"><b-icon-pencil scale="0.9"/></b-col>
                        <b-col align="left"><span>Редактировать</span></b-col>
                      </b-row>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeSection(section)" link-class="text-danger">
                      <b-row>
                        <b-col cols="1"><b-icon icon="trash-fill" /></b-col>
                        <b-col align="left"><span>Удалить</span></b-col>
                      </b-row>
                    </b-dropdown-item>
                  </a-dot-menu>
                </template>
              </template>

            </b-row>
            <hr class="py-0 my-2"/>

          </div>
        </b-row>
      </template>

    </div>
  </b-card>
</template>

<script>
export default {
  name: "SectionCard",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      sections: [],
      editingSectionIndex: null,
      lastSection: '',
    }
  },
  watch: {
    sections: {
      handler() {
        this.$emit('update-sections', this.sections);
      }
    },
    lastSection: {
      handler() {
        this.$emit('update-last-section', this.lastSection);
      }
    },
  },
  mounted() {
    this.sections = this.item.sections;
  },
  methods: {
    /**
     * Добавление секции
     */
    addSection() {
      if (this.lastSection.length > 0) {
        this.sections.push({
          title: this.lastSection,
        });
        this.lastSection = '';
      }
    },

    /**
     * Удаление секции
     * @param item
     */
    removeSection(item) {
      const elmIndex = this.sections.indexOf(item);
      const spliceLength = 1;
      this.sections.splice(elmIndex, spliceLength);
    },

    /**
     * Редактирование секции
     * @param item
     */
    editSection(item) {
      this.editingSectionIndex = this.sections.indexOf(item);
    },

    async stopEditing() {
      this.editingSectionIndex = null;
    },

    /**
     * Редактируется ли секция в данный момент
     * @param index
     */
    isEditing(index) {
      return this.editingSectionIndex === index;
    }
  },
}
</script>

<style scoped>

</style>