<template>
  <div>
    <b-card-body>
      <l-select v-if="categories.length > 0" :options="categoriesOptions" v-model="category" />
      <l-select v-else dict="dict_macros_categories" v-model="category" />
    </b-card-body>

    <!--  TODO Все под один вид  -->
    <b-form-checkbox
        v-model="isForDocument"
        class="rounded-lg ml-4 mb-3"
    >
      Для документа DOCX
    </b-form-checkbox>

    <div class="row icon-examples" id="macros-list">
      <macros-item
          v-for="(macros, index) in macroses" :key="macros.fdv + index"
          :macros="macros"
          :hide-description="hideDescription"
          :description-next-line="descriptionNextLine"
          :btn-macros-class="btnMacrosClass"
          :is-for-document="isForDocument"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import MacrosItem from "./MacrosItem.vue";
Vue.use(VueClipboard)

export default {
  name: "MacrosList",
  components: {MacrosItem},
  data() {
    return {
      category: 2,
      isForDocument: false,
    }
  },
  props: {
    hideDescription: {
      type: Boolean,
      default () {
        return false
      },
    },
    categories: {
      type: Array,
      default () {
        return []
      },
    },
    descriptionNextLine: {
      type: Boolean,
      default () {
        return false
      },
    },
    btnMacrosClass: {
      type: String,
    },
  },
  computed: {
    macroses() {
      const all = this.$store.getters.getDict('dict_macroses');
      return all.filter(elm => elm.dict_category_id === this.category);
    },
    categoriesOptions() {
      const categories = this.$store.getters.getDictItemsByIds(
          'dict_macros_categories',
          this.categories
      )
      return categories.map((item) => ({
        ...item,
        label: item.fdv,
        value: item.id
      }))
    }
  },
}
</script>

<style scoped>

</style>