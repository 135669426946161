<template>
  <div>
    <h6 class="heading-small text-muted" v-if="!hideTitle">
      Ваши данные по паспорту
      <b-btn size="sm" variant="default" class="float-right" @click="save">
        Сохранить
      </b-btn>
    </h6>
    <div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'Адрес'" required class="mb--2" />
            <b-form-input v-model="item.address" placeholder="Звенигородская ул., 28 лит А" size="sm" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'Дата рождения'" required class="mb--2" />
            <b-form-input v-model="item.birthday" placeholder="ДД.ММ.ГГГГ" size="sm" type="date" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group class="mb-2">
            <a-label :label="'СНИЛС'" required class="mb--2" />
            <b-form-input v-model="item.snils" v-inputmask="'999-999-999 99'" placeholder="123-456-789 64" size="sm" />
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserResource from "../../../../resources/user";

export default {
  name: "ProfileForms",
  props: {
    reload: Number,
    item: {
      type: Object,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.validate();
  },
  watch: {
    async reload() {
      await this.save();
    },
  },
  methods: {
    async save() {
      await this.validateAndSave(UserResource, this.item, false);
      if (!this.hideTitle)
        this.$notify({
          type: "success",
          message: "Успешно сохранено!",
        });
      this.validate();
    },
    validate() {
      if (!!this.item.address && !!this.item.birthday && this.item.snils)
        this.$emit('on-done');
    }
  }
}
</script>

<style scoped>

</style>