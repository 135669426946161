<template>
  <div>
    <div class="card card-profile" :class="`bg-${background}`">
      <div class="row justify-content-center">
        <div class="col-lg-3 order-lg-2">
          <div class="card-profile-image" @click="emitUpload">
            <a href="#">
              <img
                  v-if="!isLoading"
                  :src="avatar"
                  class="rounded-circle bg-size-cover"
                  width="180"
                  height="180"
                  :class="avatarClass"
              />
              <b-skeleton
                  v-else
                  type="avatar"
                  class="rounded-circle profile-skeleton-avatar"
                  :class="avatarClass"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="card-header bg-transparent text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
      <div class="card-body pt-0 pt-md-4 mt-5">
        <div class="row">

          <b-form-file class="col-12 mt-4 d-none" v-model="file" ref="avatarUpload" id="avatarUpload" />

          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center" :class="`text-${textColor}`">
              <div>
                <span class="heading">
                  {{ $store.getters.user.entries_num }}
                </span>
                <span class="description">Заявки</span>
              </div>
              <div>
                <span class="heading">
                  {{ currentUser.service_count }}
                </span>
                <span class="description">Оплачено</span>
              </div>
              <div>
                <span class="heading">
                  {{ currentUser.left_sum }} ₽
                </span>
                <span class="description">К оплате</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <h3 :class="`text-${textColor}`">{{ model.fio }}</h3>
          <div class="h5 font-weight-300" :class="`text-${textColor}`">
            <i class="ni location_pin mr-2" />
            {{ model.role_label }}
          </div>
          <b-badge
              pill
              class="m-1 text-white"
              :class="`bg-${profile.color}`"
              v-for="profile in model.profiles"
              :key="profile.id"
              variant="info"
              size="xl"
          >
            {{ profile.fdv }}
          </b-badge>
          <router-link to="/user/profile" v-if="!model.profiles">
            <b-alert show variant="danger">
              Заполните профили!
            </b-alert>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserResource from "../../../resources/user";

export default {
  name: "UserCard",
  data() {
    return {
      model: {
        email: "",
        fio: "",
        address: "",
        region: "",
        country: "",
        postcode: "",
        phone: "",
        organization: "",
        learned_from_us: "",
        password: "",
        note: "",
        dict_region_id: null,
        fdv: null,
        profiles: [],
      },
      file: null,
    };
  },
  props: {
    isFromProfile: {
      type: Boolean,
      default() {
        return true;
      }
    },
    avatarClass: {},
    background: {},
    textColor: {},
    user: {
      type: Object
    }
  },
  watch: {
    file: {
      async handler() {
        await this.uploadFile();
      }
    }
  },
  computed: {
    currentUser() {
      if (this.isFromProfile) {
        return this.$store.getters.user;
      } else {
        return this.user;
      }
    },
    avatar() {
      const avatarUrl = this.$store.getters.user.avatar_url;
      return this.$store.getters.user.avatar_id ?
          avatarUrl :
          '/img/no-avatar.png';
    },
  },
  mounted() {
    this.model = this.$store.getters.user;
  },
  methods: {
    async uploadFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('file', this.file);
      await UserResource.uploadAvatar(formData, this.$store.getters.user.id);
      await this.$store.dispatch('me');
      this.notifyImgUploaded();
      this.isLoading = false;
    },
    emitUpload() {
      this.$refs.avatarUpload.$refs.input.click();
    },
    notifyImgUploaded() {
      this.$notify({
        message: 'Изображение профиля успешно изменено!',
        type: 'success'
      })
    },
  },
}
</script>

<style computed>
.profile-skeleton-avatar {
  width: 180px !important;
  height: 180px !important;
  max-width: 180px;
  border-radius: 0.375rem;
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
</style>
