/*!

=========================================================
* Vue Argon Dashboard Laravel - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard-laravel
* Copyright Creative Tim (https://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)

* Coded by www.creative-tim.com & www.updivision.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import moment from 'moment'
import VueAxios from "vue-axios";
import VCalendar from 'v-calendar';
import VInputmask from 'v-inputmask';

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import IsDemo from "./plugins/isDemo"
import JoomlaLogo from "./components/Logos/JoomlaLogo";
import LSelect from "./components/Forms/LSelect";
import BColorPicker from "./components/Forms/BColorPicker";
import AIconPicker from "./components/Forms/AIconPicker";
import ValidationError from "./components/ValidationError";
import ALabel from "./components/ALabel";

import notificationMixin from "@/mixins/notification-mixin";
import formMixin from "@/mixins/form-mixin";
import fetchMixin from "@/mixins/fetch-mixin";
import saveMixin from "@/mixins/save-mixin";
import deleteMixin from "@/mixins/delete-mixin";
import permissionMixin from "@/mixins/permission-mixin";
import userMixin from "@/mixins/user-mixin";
import statisticsMixin from "@/mixins/statistics-mixin";
import ckeditorMixin from "@/mixins/ckeditor-mixin";
import AActions from "./components/AActions";
import ABadge from "./components/ABadge";
import AFile from "./components/AFile";
import ADotMenu from "./components/ADotMenu";
import constMixin from "./mixins/const-mixin";

Vue.use(VueAxios, axios);
Vue.use(IsDemo);
Vue.use(VCalendar);
Vue.use(VInputmask);

axios.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      config.withCredentials = true;
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(CKEditor);

Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

Vue.component('JoomlaLogo', JoomlaLogo);
Vue.component('LSelect', LSelect);
Vue.component('BColorPicker', BColorPicker);
Vue.component('AIconPicker', AIconPicker);
Vue.component('ValidationError', ValidationError);
Vue.component('ALabel', ALabel);
Vue.component('ADotMenu', ADotMenu);
Vue.component('AActions', AActions);
Vue.component('ABadge', ABadge);
Vue.component('AFile', AFile);

Vue.mixin(fetchMixin);
Vue.mixin(saveMixin);
Vue.mixin(deleteMixin);

Vue.mixin(notificationMixin);
Vue.mixin(formMixin);
Vue.mixin(permissionMixin);
Vue.mixin(userMixin);
Vue.mixin(statisticsMixin);
Vue.mixin(ckeditorMixin);
Vue.mixin(constMixin);

Vue.prototype.$moment = moment;
Vue.use(ArgonDashboard)
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: h => h(App)
});

store.dispatch('INITIALIZE', [
    'dict_service_types',
    'dict_service_subtypes',
    'dict_profiles',
    'dict_macros_categories',
    'dict_macroses',
    'dict_template_types',
    'dict_events',
    'dict_publication_categories',
    'dict_document_types',
]).then(() => {
    app.$mount('#app');
});

// store.$app = app;
