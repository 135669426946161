<template>
  <div class="pb-2">
    <div class="small">
      Договор #{{ entry.contract.id }} на услугу «{{ entry.service.title }}»
    </div>
    <template v-if="$store.getters.user.is_contract_allowed">
      <b-alert v-if="entry.contract.status !== contractStatuses.rejected" show variant="info" class="row mt-2 p-2">
        <b-col cols="1">
          <b-icon-info-circle scale="2" :class="{ 'mt-3' : marginIconAlert }" />
        </b-col>
        <b-col>
          Скачайте договор, подпишите его и загрузите в форму
        </b-col>
      </b-alert>
      <b-alert v-else show variant="danger" class="row mt-2 p-2">
        <b-col cols="1">
          <b-icon-info-circle scale="2" class="mt-3" />
        </b-col>
        <b-col>
          Ваши документы отклонены.
          <template v-if="entry.contract.comment">
            Комментарий оператора: {{ entry.contract.comment }}
          </template>
          Скачайте договор заново, подпишите его и загрузите в форму.
        </b-col>
      </b-alert>
      <a
          class="dotted pointer text-primary border-primary"
          :href="`/entry/${entry.id}/print/contract`"
          target="_blank"
      >
        Скачать договор
      </a>

      <b-btn size="sm" class="float-right" disabled :variant="entry.contract.statusColor">
        {{ entry.contract.statusLabel }}
      </b-btn>

      <a-label class="mt-3 mb--2" :label="'Подписанный скан договора'" required />
      <template v-if="isLoading">
        <b-skeleton class="h-25" />
      </template>
      <template v-else>
        <b-form-file
            v-if="!contractDocument || entry.contract.status === contractStatuses.rejected"
            accept=".jpg, .png, .pdf"
            v-model="contractDocument"
            placeholder="Выберите файл или перетащите его курсором..."
            drop-placeholder="Перетащите файл сюда..."
        />
        <a-file
            @update-list="fetchData"
            class="document"
            :file="contractDocument"
            hide-icon
            @on-remove="onRemove"
            is-short-text
            :hide-delete-btn="entry.status === statusDone"
        />
        <div class="mt-2 small">
          Обратите внимание, что к загрузке допускаются только файлы с расширением .pdf, .jpg или .png
          не больше 10 Мб.
        </div>
      </template>

      <b-btn
          v-if="entry.contract.status !== contractStatuses.approved"
          size="sm"
          variant="default"
          class="float-right"
          @click="save"
      >
        Сохранить
      </b-btn>
    </template>

    <template v-else>
      <b-alert show variant="warning" class="row mt-2 p-2">
        <b-col cols="1">
          <b-icon-info-circle scale="2" class="mt-3" />
        </b-col>
        <b-col>
          Для заключения договора необходимо заполнить все недостающие данные
          и загрузить документы.
        </b-col>
      </b-alert>
      <router-link class="dotted pointer text-primary border-primary" to="/user/contract">
        Перейти к заполнению данных
      </router-link>
    </template>
  </div>
</template>

<script>
import ContractResource from "../../../resources/contract";

export default {
  name: "ContractCard",
  props: {
    entry: {
      type: Object,
    },
    marginIconAlert: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      contract: null,
      contractDocument: null,
      isLoading: false,
      statusDone: 3,
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.contract = this.entry.contract;
      if (this.contract.file) this.contractDocument = this.contract.file;
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('file', this.contractDocument);
      await ContractResource.uploadFile(this.contract.id, formData);
      this.$notify({
        message: 'Файл успешно загружен!',
        type: 'success'
      })
      this.isLoading = false;
      this.$emit('on-update');
    },
    onRemove() {
      this.contractDocument = null;
    },
  }
}
</script>

<style scoped>

</style>