<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Регион
    </h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-12">
          <b-form-group label-class="form-control-label">
            <b-form-radio-group v-model="item.is_spb" stacked>
              <b-form-radio class="pr-4" :value="1"> СПб </b-form-radio>
              <b-form-radio class="pr-4" :value="0"> Другие города </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegionCard",
  props: {
    item: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>