var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('div',{staticClass:"p-2 rounded mr-3",class:{
      'bg-white': _vm.active,
      'text-white': !_vm.active,
      // 'opacity-6': done,
    }},[(!_vm.done)?[_vm._v(" Шаг "+_vm._s(_vm.step)+". ")]:[_c('b-icon-check')]],2),_c('div',{staticClass:"p-2 rounded",class:{
      'bg-white': _vm.active,
      'text-white': !_vm.active,
      // 'opacity-6': done,
    }},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }