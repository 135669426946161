<template>
  <contract-list
      :loading="isLoading"
      :items="items"
      :items-pagination="itemsPagination"
      title="Ожидают проверки"
      subtitle="Договоры, ожидающие проверки"
  >
    <div class="text-center my-5" v-if="isLoading">
      <span class="loader" />
    </div>
    <contract-card v-for="item in items" :item="item" :key="item.id" v-else>
      <template #footer>
        <div class="card-footer bg-transparent mt-2" v-if="item.file">
          <b-btn variant="link" size="sm" @click="showRejectionForm(item)">
            Отклонить
          </b-btn>
          <b-btn variant="link" size="sm" @click="accept(item)">
            Принять
          </b-btn>
        </div>
      </template>
    </contract-card>

    <b-modal
        id="rejectionModal"
        ref="rejectionModal"
        size="sm"
        title="Отклонение документов"
        ok-title="Отклонить"
        @ok="reject"
        cancel-title="Отмена"
        centered
    >
      <div v-if="selected.user" class="mt--3">
        <h5 class="mb-3 card-title">
          {{ selected.user.fio }}
        </h5>
        <h5 class="card-subtitle font-weight-normal mb-2">
          {{ selected.service.title }}
        </h5>
      </div>
      <b-form-group class="my-0">
        <a-label label="Причина отклонения" class="mb--2" />
        <b-form-textarea v-model="selected.comment" placeholder="Оставьте поле пустым, если комментариев нет" rows="6" />
      </b-form-group>
    </b-modal>

  </contract-list>
</template>
<script>
import ContractResource from "../../../../resources/contract";
import ContractList from "./ContractList.vue";
import ContractCard from "../ContractCard.vue";

export default {
  name: 'ContractListPending',
  components: {ContractCard, ContractList},
  data() {
    return {
      items: [],
      itemsPagination: {},
      selected: {},
    }
  },
  props: {
    reload: Number,
    filters: Object,
  },
  watch: {
    async reload() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filters.status = this.contractStatuses.pending;
      await this.fetch(ContractResource, this.filters);
    },
    showRejectionForm(item) {
      this.selected = item;
      this.$bvModal.show('rejectionModal');
    },
    async reject() {
      await ContractResource.reject(this.selected.id, this.selected);
      this.$emit('on-update');
    },
    async accept(item) {
      await ContractResource.accept(item.id);
      this.$emit('on-update');
    },
  }
}
</script>