<template>
  <div>
    <template>
      <form @submit.prevent>

        <div class="d-flex float-right" v-if="!isManager">
          <b-btn size="sm" variant="info" class="mr-3" @click="emitUpload">
            Изменить изображение профиля
          </b-btn>
          <router-link to="settings">
            <b-btn size="sm" variant="default" class="float-right">
              Изменить пароль
            </b-btn>
          </router-link>
        </div>

        <div :class="{ 'row': isManager }">

          <b-col v-if="isManager" lg="4" class="border-right bg-secondary">
            <!-- Управление -->
            <management-card :item="item" />

            <!-- Пароль -->
            <template v-if="!isNew">
              <hr class="my-4" />
              <password-card :item="item"/>
            </template>

            <template v-if="item.role === roleGuest">
              <hr class="my-4" />
              <region-card :item="item"/>
            </template>
          </b-col>

          <div :class="{ 'col-lg-8': isManager }">
            <!-- Основная информация -->
            <base-info-card :item="item" />
            <hr class="my-4" />

            <!-- Информация о работе -->
            <job-card :item="item" @select-profiles="selectProfiles" />
            <hr class="my-4" />

            <!-- Адрес -->
<!--            <address-card :item="item" />-->
<!--            <hr class="my-4" />-->
          </div>
        </div>

        <div class="text-center">
          <b-button variant="primary" @click="save" pill :class="{ 'float-right': isManager }">
            Сохранить
          </b-button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import UserResource from "../../../resources/user";
import BaseInfoCard from "./UserInfo/BaseInfoCard";
import JobCard from "./UserInfo/JobCard";
import AddressCard from "./UserInfo/AddressCard";
import ManagementCard from "./UserInfo/ManagementCard";
import PasswordCard from "./UserInfo/PasswordCard";
import RegionCard from "./UserInfo/RegionCard.vue";

export default {
  name: "UserInfo",
  components: {
    RegionCard,
    PasswordCard,
    ManagementCard,
    // AddressCard,
    JobCard,
    BaseInfoCard
  },
  data() {
    return {
      item: {
        email: "",
        last_name: "",
        first_name: "",
        middle_name: "",
        address: "",
        region: "",
        country: "",
        postcode: "",
        phone: "",
        organization: "",
        learned_from_us: "",
        password: "",
        note: "",
        dict_region_id: null,
        fdv: null,
        profiles: [],
      },
      checkedProfiles: [],
      errors: [],
      roleGuest: 3,
    };
  },
  props: {
    isNew: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isFromProfile: {
      type: Boolean,
      default() {
        return true;
      }
    },
    user: {
      type: Object
    }
  },
  computed: {
    currentUser() {
      if (this.isFromProfile) {
        return this.$store.getters.user;
      } else {
        return this.user;
      }
    },
    regions() {
      return this.$store.getters.getDict('dict_regions');
    },
    profiles() {
      return this.$store.getters.getDict('dict_profiles');
    },
  },
  mounted() {
    this.item = this.currentUser;
  },
  methods: {
    async save() {
      console.log(this.item);
      await this.validateAndSave(UserResource);
      this.errors = this.apiValidationErrors;
      await this.saveUserProfiles();
      this.$emit('on-update');
      if (this.isFromProfile)
        await this.$store.dispatch('me');
    },
    selectProfiles(profiles) {
      this.checkedProfiles = profiles;
    },
    convertUserProfiles() {
      this.checkedProfiles = this.profiles.filter(elm =>
          this.checkedProfiles.indexOf(elm.id) !== -1
      )
    },
    async saveUserProfiles() {
      this.convertUserProfiles();
      await UserResource.storeProfiles(this.checkedProfiles, this.item.id);
    },
    emitUpload() {
      const input = document.getElementById('avatarUpload');
      input.click();
    }
  },
}
</script>

<style scoped>

</style>