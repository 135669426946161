<template>
  <div class="d-flex justify-content-between align-items-baseline row">
<!--  <b-list-group-item class="d-flex justify-content-between align-items-baseline row">-->
    <b-col lg="6" cols="12">
      <step-title v-if="number" :step="number" :active="active" :done="done">
        <slot name="title" />
      </step-title>
<!--      class="p-2 rounded mt-3 nav-item active router-link-active opacity-8 border-0 text-white small"-->
      <div
          v-if="!disabled && !done"
      >
        <slot name="description" />
      </div>
    </b-col>
    <b-col lg="6" cols="12">
      <div class="text-right mb--2" v-if="!disabled && !done && currentStep">
        <b-btn class="px-3 py-2" :disabled="btnDisabled" @click="$emit('next-step')">
          Далее
        </b-btn>
      </div>
      <slot v-if="!disabled && !done" />
    </b-col>
  </div>
</template>

<script>
import StepTitle from "./StepTitle.vue";

export default {
  name: "Step",
  components: {
    StepTitle
  },
  props: {
    currentStep: {
      type: Number
    },
    number: {
      type: Number
    },
    btnDisabled: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
    active() {
      return this.number === this.currentStep
    },
    done() {
      return this.number < this.currentStep
    },
    disabled() {
      return this.number > this.currentStep
    }
  },
}
</script>

<style scoped>

</style>