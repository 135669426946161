<template>
  <div class="pb-2">
    <h6 class="heading-small text-muted" v-if="!hideTitle">
      Документы
      <b-btn size="sm" variant="default" class="float-right" @click="save">
        Сохранить
      </b-btn>
    </h6>

    <a-label class="mb--2" :label="'Подписанный скан согласия на обработку персональных данных'" required />
    <template v-if="isLoading">
      <b-skeleton class="h-25" />
    </template>
    <template v-else>
      <b-form-file
          v-if="!consentDocument"
          accept=".jpg, .png, .pdf"
          v-model="consentDocument"
          placeholder="Выберите файл или перетащите его курсором..."
          drop-placeholder="Перетащите файл сюда..."
      />
      <a-file @update-list="fetchData" class="document" :file="consentDocument" hide-icon hide-delete-btn />
    </template>
    <div class="mt-2 small">
      Обратите внимание, что к загрузке допускаются только файлы с расширением .pdf, .jpg или .png
      не больше 10 Мб.
    </div>
  </div>
</template>

<script>
import UserResource from "../../../../resources/user";

export default {
  name: "ConsentUpload",
  data() {
    return {
      consentDocument: null,
      isLoading: false,
    }
  },
  props: {
    reload: Number,
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    async reload() {
      await this.save();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const documents = await UserResource.fetchDocuments(this.$store.getters.user.id);
      this.consentDocument = documents.consent;
      if (this.consentDocument) this.$emit('on-done');
      this.isLoading = false;
    },
    async save() {
      const userId = this.$store.getters.user.id;
      this.isLoading = true;
      let formData = new FormData();
      formData.append('consent', this.consentDocument);
      await UserResource.uploadDocuments(userId, formData);
      if (!this.hideTitle)
        this.$notify({
          message: 'Файл успешно загружен!',
          type: 'success'
        })
      this.isLoading = false;
      this.$emit('on-done');
    },
  }
}
</script>

<style scoped>

</style>