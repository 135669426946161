<template>
  <b-modal
      hide-footer
      id="itemEditModal"
      size="xl"
      title="Тип услуги"
      :header-class="`bg-${item.color}`"
      :title-class="`text-${colorTitle}`"
      :header-close-variant="colorTitle"
  >
    <b-row class="mt-3">
      <b-col lg="5">
        <b-color-picker @on-update-color="setColor" :is-text="true" :value="item.color" />
      </b-col>

      <b-col>
        <b-row>
          <b-col lg="5">
            <b-form-group label="Название (полн.)">
              <b-form-input v-model="item.fdv" placeholder="Название (полн.)" />
            </b-form-group>
          </b-col>
          <b-col lg="5">
            <b-form-group label="Название (кр.)">
              <b-form-input v-model="item.dv" placeholder="Название (кр.)" />
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group label="Баллы">
              <b-form-input v-model="item.points" placeholder="Баллы" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Описание">
          <b-form-textarea rows="3" v-model="item.description" placeholder="Описание" />
        </b-form-group>

        <b-form-group label="Нужны документы">
          <l-select
              :options="booleanChoice"
              v-model="item.is_contract_needed"
              :allow-empty="false"
          />
        </b-form-group>

        <!--    Категории для публикаций на Joomla - это рубрики публикаций   -->
        <template v-if="!item.is_publication">
          <p class="text-dark">Категория на <joomla-logo /> {{ joomlaSystemLabel }} </p>
          <l-select
              :disabled="joomlaCategories.length < 1"
              :options="joomlaCategories"
              v-model="item.joomla_category_id"
          />
        </template>
      </b-col>
    </b-row>

    <b-form-group label="Подтипы" class="mt-4">
      <base-table
          class="table align-items-center table-flush table-responsive"
          tbody-classes="list"
          :data="serviceSubTypes"
          :thead-classes="'thead-light'"
      >
        <template slot="columns">
          <th><span class="ml-3">Заголовок</span></th>
          <th>Joomla ID</th>
          <th>Баллы</th>
          <th>СПб</th>
          <th></th>
        </template>
        <template slot-scope="{ row }">
          <td class="text-wrap col-3">
            <template v-if="row.id !== subtypeEditingId">
              <span>
                {{ row.fdv }}
              </span><br>
              <span class="text-muted">
                {{ row.dv }}
              </span>
            </template>
            <template v-else>
              <b-form-input size="sm" v-model="row.fdv" placeholder="Название (полн.)"/>
              <b-form-input size="sm" v-model="row.dv" placeholder="Название (кратк.)" class="mt-2"/>
            </template>
          </td>

          <td class="text-center col-4">
            <l-select
                v-if="!item.is_publication"
                class="text-wrap"
                :disabled="row.id !== subtypeEditingId || joomlaCategories.length < 1"
                :options="joomlaCategories"
                v-model="row.joomla_category_id"
            />
            <l-select
                v-if="item.is_publication"
                class="text-wrap"
                :disabled="row.id !== subtypeEditingId || joomlaCategories.length < 1"
                :options="joomlaCategories"
                v-model="row.joomla_tag_id"
            />
          </td>

          <td class="text-center col-1">
            <h2 v-if="row.id !== subtypeEditingId" class="mr-5 pr-4">
              <b-badge variant="info" class="bg-white">
                {{ row.points }}
              </b-badge>
            </h2>
            <b-form-input
                v-else
                size="sm"
                class="col-6"
                v-model="row.points"
                placeholder="0"
            />
          </td>

          <td class="text-center col-1">
            <h2 v-if="row.id !== subtypeEditingId" class="mr-5 pr-4">
              <b-badge variant="info" class="bg-white">
                {{ row.is_spb ? 'Да' : '-' }}
              </b-badge>
            </h2>
            <l-select
                v-else
                :options="booleanChoice"
                placeholder-text="Город"
                v-model="row.is_spb"
                class="overflow-hidden mt-1"
                :allow-empty="false"
            />
          </td>

          <td class="text-center">
            <b-btn
                variant="primary"
                class="mr-2"
                @click="editSubtype(row)"
                v-if="row.id !== subtypeEditingId"
            >
              <b-icon icon="pencil-square" /> Редактировать
            </b-btn>

            <template v-else>
              <b-btn variant="success" class="mr-2" @click="saveSubtype(row)">
                <b-icon-check /> Сохранить
              </b-btn>
              <b-btn variant="danger" class="mr-2" @click="cancelEditing">
                <b-icon-x />
              </b-btn>
            </template>

            <!--              <b-btn tag="a" variant="danger" disabled>-->
            <!--                <b-icon icon="trash-fill" />-->
            <!--              </b-btn>-->
          </td>
        </template>
      </base-table>
    </b-form-group>

    <b-form-group label="Иконка">
      <a-icon-picker
          @on-update-icon="setIcon"
          :is-text-shown="false"
          btn-class="btn"
      />
    </b-form-group>

    <hr class="my-4" />

    <div class="text-right">
      <b-button variant="primary" @click="save" pill>
        Сохранить
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import DictResource from "../../../../resources/dict";
import JoomlaResource from "../../../../resources/joomla";

export default {
  name: "ItemEditModal",
  props: {
    isNew: {
      type: Boolean,
    },
    item: {
      type: Object,
    }
  },
  data() {
    return {
      serviceSubTypes: [],
      subtypeEditingId: null,
      joomlaCategories: [],
      booleanChoice: [
        { value: 1, label: 'Да' },
        { value: 0, label: 'Нет' },
      ],
    }
  },
  computed: {
    title() {
      return this.isNew ? 'Создание' : 'Редактирование';
    },
    colorTitle() {
      return this.item.color ? 'white' : 'dark'
    },
    serviceSubTypesAll() {
      return this.$store.getters.getDict('dict_service_subtypes');
    },
    joomlaSystemLabel() {
      const label = this.item.is_publication ? 'Экстернат.РФ' : 'aneks.center';
      return `(${label})`;
    }
  },
  watch: {
    item() {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      const joomlaSystem = this.item.is_publication ?
          JoomlaResource.jSystems.journal :
          JoomlaResource.jSystems.site;
      this.fetchServiceSubTypes();
      await this.fetchJoomlaCategories(joomlaSystem);
    },
    setColor(color) {
      this.item.color = color;
    },
    fetchServiceSubTypes() {
      const itemId = this.item.id;
      let subtypes = this.serviceSubTypesAll;
      this.serviceSubTypes = subtypes.filter(
          elm => elm.dict_service_type_id === itemId
      );
    },
    async fetchJoomlaCategories(joomlaSystem) {
      const joomlaCategories = await JoomlaResource.fetchCategories(joomlaSystem);
      this.joomlaCategories = joomlaCategories.map(item => ({
        ...item,
        label: `${item.id}. ${item.title} (${item.alias})`,
        value: parseInt(item.id),
      }));
    },
    setIcon(icon) {
      this.item.icon = icon;
    },
    /**
     * Включает режим редактирования подтипа услуг
     * @param row
     */
    editSubtype(row) {
      this.subtypeEditingId = row.id;
    },
    /**
     * Сохраняет подтип услуги
     * @param row
     * @returns {Promise<void>}
     */
    async saveSubtype(row) {
      DictResource.setUrl('dict_service_subtypes');
      await DictResource.save(row);
      this.$notify({
        type: 'success',
        message: 'Успешно сохранено!'
      });
      this.cancelEditing();
    },
    /**
     * Отменяет режим редактирования подтипа услуг
     */
    cancelEditing() {
      this.subtypeEditingId = null;
    },
    /**
     * Сохраняет тип услуг
     * @returns {Promise<void>}
     */
    async save() {
      DictResource.setUrl('dict_service_types');
      await this.validateAndSave(DictResource);
    }
  }
}
</script>

<style scoped>

</style>