<template>

  <div>
    <base-header type="gradient-indigo" class="pt-0 pt-md-5"/>
    <span class="mask" />
    <div class="container-fluid mt-5">

      <div class="mb-3 px-3 row">
        <b-col>
          <b-input-group prepend="🔎" size="sm" class="input-flat">
            <b-form-input
                placeholder="Поиск по ФИО"
                class="bg-transparent text-white"
                autofocus
                v-model="filters.fio"
                @input="onUpdate"
            />
          </b-input-group>
        </b-col>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 col-xl-4">
          <contract-list-pending key="pending" :reload="reload" :filters="filters" @on-update="onUpdate" />
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <contract-list-approved key="approved" :reload="reload" :filters="filters" @on-update="onUpdate" />
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
          <contract-list-rejected key="rejected" :reload="reload" :filters="filters" @on-update="onUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractListPending from "./List/ContractListPending.vue";
import ContractListApproved from "./List/ContractListApproved.vue";
import ContractListRejected from "./List/ContractListRejected.vue";

export default {
  name: 'ContractMain',
  components: {
    ContractListRejected,
    ContractListApproved,
    ContractListPending
  },
  data() {
    return {
      filters: {
        fio: null,
      },
      reload: 0,
    }
  },
  methods: {
    onUpdate() {
      this.reload++;
    },
  }
}
</script>

<style>
.input-flat input {
  border: none;
}
.input-flat .input-group-text {
  background: transparent;
  border: none;
}
</style>