<template>
  <div>
    <base-header
        class="pt-3 pt-md-6"
    >
      <!-- Mask -->
      <span class="mask"></span>
      <!-- Header container -->
      <div class="my--4" v-if="!user.is_documents_upload_allowed">
        <div class="row">
          <b-alert show class="nav-item active router-link-active opacity-8 border-0">
            Загрузка документов доступна только записавшимся на курсы
          </b-alert>
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div class="row">
<!--        <div class="col-xl-11 order-xl-2 mb-5 mb-xl-0" v-if="user.is_documents_upload_allowed">-->
        <div class="col-12 mb-5 mb-xl-0" v-if="user.is_documents_upload_allowed">
          <step-list />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StepList from "./Step/StepList.vue";

export default {
  name: "Contract",
  components: {
    StepList
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  }
};
</script>
<style></style>
