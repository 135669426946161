<template>
  <div class="card-border-primary bg-transparent">
    <div class="pt-0 card-header bg-transparent pb-0">
      <div class="card-actions float-right">
<!--        <div class="dropdown show">-->
<!--          <a href="#" data-toggle="dropdown" data-display="static">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal align-middle">-->
<!--              <circle cx="12" cy="12" r="1"></circle>-->
<!--              <circle cx="19" cy="12" r="1"></circle>-->
<!--              <circle cx="5" cy="12" r="1"></circle>-->
<!--            </svg>-->
<!--          </a>-->
<!--        </div>-->
        <b-badge variant="dark" class="text-white">
          {{ itemsPagination.total }}
        </b-badge>
      </div>
      <h5 class="card-title text-white">
        {{ title }}
      </h5>
      <h6 class="card-subtitle text-white">
        {{ subtitle }}
      </h6>
    </div>
    <div class="card-body p-3">
      <slot>
        <div class="text-center my-5" v-if="loading">
          <span class="loader" />
        </div>
        <template v-else>
          <contract-card v-for="item in items" :item="item" :key="item.id">
            <template #footer>
              <slot name="footer" />
            </template>
          </contract-card>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>

import ContractCard from "../ContractCard.vue";

export default {
  components: {ContractCard},
  props: {
    items: {
      type: Array
    },
    itemsPagination: {
      type: Object
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    loading: {
      type: Boolean
    },
  }
}
</script>

<style>

</style>