<template>
  <b-nav-item-dropdown class="dots-actions" right>
    <template #button-content>
      <a class="pointer text-xs mr-3" v-if="item.file" :href="item.file.url" target="_blank">
        <b-icon-filter-square class="mt-1 mr-1" variant="dark" />
        <span class="dotted h5 font-weight-normal"> Печать документов </span>
      </a>
    </template>
    <b-dropdown-item
        v-for="document in item.documents"
        :key="document.id"
        :href="document.url"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-filter-square /></b-col>
        <b-col align="left"><span>
                {{ $store.getters.getDictItemById('dict_document_types', document.dict_document_type_id).dv }}
              </span></b-col>
      </b-row>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: "UserDocumentsPrint",
  props: {
    item: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>