<template>
  <b-modal
      id="contractModal"
      ref="contractModal"
      size="md"
      title="Заключение договора"
      centered
      ok-title="Подтвердить"
      cancel-title="Отклонить"
      @ok="accept"
      @cancel="reject"
      footer-class="mt--3"
      header-class="mb--3"
      :hide-footer="!isManager"
  >
    <contract-card :entry="entry" :margin-icon-alert="false" @on-update="$emit('on-update')" />
    <b-form-group class="mt-0 mb-2" v-if="isManager">
      <a-label label="Комменатрий оператора" class="mb--2" />
      <b-form-textarea v-model="entry.contract.comment" placeholder="Оставьте поле пустым, если комментариев нет" rows="4" />
    </b-form-group>
  </b-modal>
</template>

<script>
import ContractCard from "../Contract/ContractCard.vue";
import ContractResource from "../../../resources/contract";

export default {
  name: "ContractModal",
  components: {ContractCard},
  props: {
    entry: {
      type: Object,
    }
  },
  methods: {
    async reject() {
      await ContractResource.reject(this.entry.contract.id, this.entry.contract);
      this.$emit('on-update');
    },
    async accept() {
      await ContractResource.accept(this.entry.contract.id);
      this.$emit('on-update');
    },
  },
}
</script>


<style scoped>

</style>