<template>
  <contract-list
      :loading="isLoading"
      :items="items"
      :items-pagination="itemsPagination"
      title="Отклонённые"
      subtitle="Отклонённые договоры"
  >

  </contract-list>
</template>
<script>
import ContractResource from "../../../../resources/contract";
import ContractList from "./ContractList.vue";

export default {
  name: 'ContractListRejected',
  components: {ContractList},
  data() {
    return {
      items: [],
      itemsPagination: {},
    }
  },
  props: {
    reload: Number,
    filters: Object,
  },
  watch: {
    async reload() {
      await this.fetchData();
    },
  },
  async mounted() {
    this.$on('on-update', this.fetchData);
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.filters.status = this.contractStatuses.rejected;
      await this.fetch(ContractResource, this.filters);
    },
  },
}
</script>