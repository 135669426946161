<template>
  <div>
      <div class="table-responsive mt-1">

        <base-table
            :loading="isLoading"
            class="table align-items-center table-flush"
            tbody-classes="list"
            :data="items"
            :thead-classes="'thead-light'"
        >
          <template slot="columns">
            <th></th>
            <th>
              <base-input
                  alternative
                  v-model="filters.title"
                  lazy
                  @change="fetchDataWithFilters"
                  addon-left-icon="fas fa-search"
                  placeholder="Заголовок"
                  class="input-group-alternative m-0"
              />
            </th>
            <th class="text-center">Дата</th>
            <th class="text-center">Баллы</th>
            <th class="text-center">Цена</th>
<!--            <th>Заочно</th>-->
            <th class="text-center">Участники</th>
<!--            <th>Места (всего)</th>-->
            <th></th>
          </template>

          <template slot-scope="{ row }" v-if="issetServices">
            <td class="text-lg" :class="{'opacity-5': !row.is_visible}">
              <i :class="row.date_type_icon" class="d-none d-md-block ml-2" />
            </td>
            <td class="col-4 text-wrap" :class="{'opacity-5': !row.is_visible}">
              {{ row.title }}
              <b-badge size="xs" class="text-dark opacity-5"> #{{ row.id }} </b-badge>
            </td>
            <td class="text-center">
              <template v-if="row.date_type === dateTypeCurrent">
                {{ row.interval_formatted }}
              </template>
            </td>
            <td class="text-center">
              <h2><b-badge variant="info" class="bg-white">
                {{ row.points }}
              </b-badge></h2>
            </td>
            <td class="text-center">
              <h2><b-badge variant="warning">
                {{ row.price }} ₽
              </b-badge></h2>
            </td>
<!--            <td>{{ row.participation_type }}</td>-->
            <td class="text-center">
              <b>{{ row.count_participants }}</b>
              <b-icon-people-fill class="ml-2" />
            </td>
<!--            <td class="text-center">-->
<!--              <b>{{ row.max_participants }}</b>-->
<!--              <b-icon-people-fill class="ml-2" />-->
<!--            </td>-->
            <td>
              <b-btn tag="a" variant="dark" class="mr-2" @click="showEntries(row)">
                <i class="ni ni-align-center" />
              </b-btn>
              <b-btn tag="a" variant="primary" class="mr-2" @click="edit(row)">
                <b-icon icon="pencil-square" />
              </b-btn>
              <service-actions :item="row" @on-update="fetchData" />
            </td>
          </template>
        </base-table>
      </div>

      <service-entries-modal :service="selectedService" />
      <div class="card-footer d-flex justify-content-end">
        <base-pagination
            v-if="!isLoading"
            :page-count="itemsPagination.last_page"
            :value="itemsPagination.current_page"
            @step="fetchData"
        />
      </div>
  </div>
</template>
<script>
import ServiceResource from "../../../resources/service";
import ServiceEntriesModal from "./ServiceEntriesModal";
import ServiceActions from "./ServiceActions";

export default {
  name: "ServiceTable",
  components: {
    ServiceEntriesModal,
    ServiceActions
  },
  data() {
    return {
      isLoading: true,
      items: [],
      selectedService: {},
      filters: {
        title: null,
      },
      dateTypeCurrent: 1,
      itemsPagination: null,
    };
  },
  computed: {
    issetServices() {
      return this.items.length > 0;
    }
  },
  props: {
    typeId: {},
    subtypeId: {},
    reload: {},
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    typeId: {
      handler() {
        this.fetchData();
      }
    },
    subtypeId: {
      handler() {
        this.fetchData()
      },
    },
    reload: {
      async handler() {
        console.log('reloading....');
        await this.fetchData();
      }
    },
  },
  methods: {
    async fetchData(page, perPage) {
      this.filters = {
        dict_type_id: this.typeId,
        dict_subtype_id: this.subtypeId,
        title: this.filters.title,
      };
      this.fetch(ServiceResource, this.filters, page, perPage)
    },
    edit(item) {
      this.$emit('show-modal', item);
    },
    showEntries(item) {
      this.selectedService = item;
      this.$bvModal.show('serviceEntriesModal');
    },
  },
};
</script>
<style>
</style>
