<template>
  <div>
    <base-header type="gradient-indigo" class="text-white pt-md-6">
    </base-header>
<!--    <span class="mask bg-dark" />-->
    <div class="container-fluid bg-transparent w-75">
      <notification-list />
    </div>
  </div>
</template>

<script>
import NotificationList from "./NotificationList";

export default {
  name: "Notification",
  components: {
    NotificationList,
  },
}
</script>