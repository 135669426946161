<template>
  <div>

    <b-row v-if="!hideFilters" class="px-3">
      <b-col cols="12" class="mb-2">
        <a-label label="Номер" label-class="mb-0" />
        <b-input v-model="filters.number" placeholder="№" @change="changePageWithFilters" />
      </b-col>
      <b-col cols="12" class="mb-2" v-if="!hideFio">
        <a-label label="ФИО" label-class="mb-0" />
        <b-input v-model="filters.fio" placeholder="ФИО" @input="changePageWithFilters" />
      </b-col>
      <b-col cols="12" class="mb-2" v-if="!hideService">
        <a-label label="Тип услуги" label-class="mb-0" />
        <l-select
            :options="typesAll"
            placeholder-text="..."
            v-model="filters.type"
            @input="changePageWithFilters"
            class="overflow-hidden"
            add-default-option
        />
      </b-col>
      <b-col cols="12" class="mb-2" v-if="!hideService">
        <a-label label="Услуга" label-class="mb-0" />
        <b-input v-model="filters.service" placeholder="Мероприятие" @input="changePageWithFilters" />
      </b-col>
      <b-col cols="12" class="mb-2" v-if="showSection"></b-col>
      <b-col cols="12" class="mb-2">
        <a-label label="Статус" label-class="mb-0" />
        <l-select
            :options="statuses"
            placeholder-text="..."
            v-model="filters.status"
            @input="changePageWithFilters"
            class="overflow-hidden"
            add-default-option
        />
      </b-col>
      <b-col cols="12" class="mb-2" v-if="!hideCreatedAt && isManager">
        <a-label label="Дата" label-class="mb-0" />
        <b-input
            v-model="filters.date"
            v-inputmask="'99.99.9999'"
            placeholder="Дата"
            @input="changePageWithFilters"
        />
      </b-col>
      <b-col cols="12" class="mb-2"></b-col>
      <b-col cols="12" class="mb-2"></b-col>
    </b-row>

    <div class="table-responsive">
      <base-table
          :loading="loading"
          class="table align-items-center table-flush"
          tbody-classes="list dashed"
          :data="entries"
          :thead-classes="'thead-light'"
          :skeleton-columns="[0, 1, 2, 3, 4, 5, 6, 7]"
      >
        <template slot-scope="{ row }" v-if="!loading">
          <td class="text-wrap">
            <b-row>
              <b-col cols="6">
                # {{ row.number }}
                <template class="text-xs" v-if="!hideFio">
                  <br> {{ row.user.fio }}
                </template>
              </b-col>

              <!--        Оплата/статус        -->
              <b-col cols="6">
                <template v-if="canBePaid(row)">
                  <b-btn
                      v-b-tooltip.left="'Оплатить'"
                      class="pl-2 pr-2 p-1 bg-gradient-danger border-0 text-white float-right"
                      @click="pay(row, true)"
                  >
                    {{row.price}} ₽
                  </b-btn>
                </template>
                <template v-else-if="row.publication">
                  <h4><b-badge :variant="row.publication.statusColor" class="float-right">
                    {{ row.publication.statusLabel }}
                  </b-badge></h4>
                </template>
                <template v-else>
                  <h4><b-badge :variant="row.statusColor" class="float-right">
                    {{ row.statusLabel }}
                  </b-badge></h4>
                </template>

                <!--        Редактирование заявки        -->
                <template v-if="(!row.is_paid && !isManager) || isManager">
                  <a-actions class="float-right mt--2" :item="row" @edit="editItem" @remove="remove" :disable-remove="row.status >= statusPaid">
                    <b-dropdown-item v-if="row.publication && row.publication.url" :href="row.publication.url" target="_blank">
                      <b-row>
                        <b-col cols="1"><i class="ni ni-curved-next" /></b-col>
                        <b-col align="left"><span>Ссылка</span></b-col>
                      </b-row>
                    </b-dropdown-item>
                    <b-dropdown-item @click="editPublication(row)" v-if="row.service.is_publication">
                      <b-row>
                        <b-col cols="1"><i class="ni ni-single-copy-04" /></b-col>
                        <b-col align="left"><span>Публикация</span></b-col>
                      </b-row>
                    </b-dropdown-item>
                  </a-actions>
                </template>
              </b-col>

              <b-col cols="12" class="my-2" />

              <!--     Услуга       -->
              <b-col cols="2">
                <i
                    :class="`${row.service.type.icon} text-${row.service.type.color} pt-2`"
                    class="text-xl ml-2"
                />
              </b-col>
              <b-col cols="10">
                <span class="text-wrap font-weight-bolder">
                  {{ getServiceType(row) }}
                </span>
                {{ row.service.title }} <br>
                <template v-if="row.publication">
                  <i>«{{ row.publication.title }}»</i><br>
                  <a :href="row.publication.url" target="_blank" v-if="row.publication.url">
                    <b-icon-link45deg />
                    Просмотреть на сайте
                  </a>
                </template>
              </b-col>

              <!--       ДАТА         -->
              <template v-if="row.service.date_type === dateTypeCurrent">
                <b-col cols="2" />
                <b-col cols="10" :class="{ 'text-primary': row.status !== statusDone }">
                  <b-icon-calendar-date
                      :class="`${row.status === statusDone ? '' : 'text-primary'}`"
                      class="mr-1"
                  />
                  {{ row.service.interval_formatted }}
                </b-col>
              </template>

              <!--     Добавление тезисов/просмотр публикации     -->
              <b-col cols="2" />
              <b-col cols="10" class="mt-1" >
                <template v-if="row.is_thesis_needed">
                  <!--     Добавление тезисов       -->
                  <a
                      href="#"
                      class="text-warning"
                      v-if="!row.service.is_publication && (!isManager && !row.thesis)"
                      @click="changeThesis(row)"
                  >
                      <span class="dotted pointer text-sm mr-3 border-warning">
                        + <i class="ni ni-tag mr-2" /> Добавить тезисы
                      </span>
                  </a>
                  <!--     Просмотр добавленных тезисов       -->
                  <a
                      href="#"
                      v-if="!row.service.is_publication && !!row.thesis"
                      @click="changeThesis(row)"
                  >
                      <span class="dotted pointer text-sm mr-3 border-primary">
                        <b-icon-eye-fill class="mr-1" /> Просмотр тезисов
                      </span>
                  </a>
                </template>
                <template v-if="row.service.is_publication">
                  <h4>
                    <b-badge
                        v-if="row.publication"
                        :variant="row.publication.statusColor"
                    >
                      {{ row.publication.statusLabel }}
                    </b-badge>
                  </h4>
                  <a
                      href="#"
                      class="text-default"
                      @click="editPublication(row)"
                  >
                      <span class="dotted pointer text-sm mr-3 border-default">
                        <b-icon-card-text class="mr-1" /> Просмотр публикации
                      </span>
                  </a>
                </template>
              </b-col>

              <!--    ПЕЧАТЬ ДОКУМЕНТОВ     -->
              <b-col cols="2" />
              <b-col class="mt-2">
                <template v-if="row.status === statusDone || isManager">
                  <entry-print v-b-tooltip.right="'Печать документов'" :item="row">
                    <template v-slot:button>
                      <span class="dotted pointer text-sm mr-3">
                        <b-icon-printer-fill font-scale="1.1" class="mr-1" /> Документы
                      </span>
                    </template>
                  </entry-print>
                </template>
              </b-col>
            </b-row>

          </td>

        </template>

      </base-table>
    </div>

    <router-link to="/user/service" v-if="!entriesPagination.total">
      <b-alert variant="warning" show class="m-4">
        У Вас нет ни одной заявки! Подайте заявку на услугу из раздела "Услуги"
      </b-alert>
    </router-link>

      <entry-edit-modal :entry="selected" />
      <publication-entry-modal :entry="selected" @on-update="$emit('on-update')" />
      <entry-thesis-modal :entry="selected" @on-update="$emit('on-update')"/>

    <div
        v-if="entriesPagination.last_page > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          :page-count="entriesPagination.last_page"
          :value="entriesPagination.current_page"
          @step="changePage"
      />
    </div>

  </div>
</template>

<script>
import paymentMixin from "../../../mixins/payment-mixin";
import EntryResource from "../../../resources/entry";
import EntryThesisModal from "./EntryThesisModal";
import EntryEditModal from "./EntryEditModal";
import EntryPrint from "./EntryPrint";
import PublicationEntryModal from "./PublicationEntryModal.vue";

export default {
  name: "EntryList",
  components: {
    EntryThesisModal,
    EntryEditModal,
    PublicationEntryModal,
    EntryPrint
  },
  mixins: [
    paymentMixin
  ],
  data() {
    return {
      filters: {
        id: null,
        fio: null,
        service: null,
        status: null,
        date: null,
      },
      selected: {
        thesis: {},
        service: {
          sections: [],
        },
        publication: {
          status: 1
        },
        user: {},
      },
      statuses: [
        { value: 1, label: 'Не оплачена' },
        { value: 2, label: 'Оплачена' },
        { value: 3, label: 'Завершена' },
      ],
      statusNotPaid: 1,
      statusDone: 3,
      dateTypeCurrent: 1,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    entries: {
      type: Array,
    },
    entriesPagination: {
      type: Object,
    },
    hideFio: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideService: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideCreatedAt: {
      type: Boolean,
      default() {
        return false;
      }
    },
    hideFilters: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showSection: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  methods: {
    /**
     * Может ли пользователь оплатить заявку
     * @param entry
     * @returns {*|boolean|(function(): boolean)}
     */
    canBePaid(entry) {
      return entry.status === this.statusNotPaid;
    },
    /**
     * Может ли пользователь оплатить заявку
     * (до 2023 года, с учётом статуса публикаций)
     * @param entry
     * @returns {*|boolean|(function(): boolean)}
     * @deprecated
     */
    canBePaidOld(entry) {
      const statusApproved = 2;
      const isPublication = entry.publication;
      const entryNotPaid = entry.status === this.statusNotPaid;
      const publicationAccepted = isPublication
          && entry.publication.status === statusApproved;

      const canPayEntry = !isPublication && entryNotPaid;
      const canPayPublication = entryNotPaid && publicationAccepted;

      return canPayEntry || canPayPublication;
    },
    async remove(entry) {
      await this.deleteItem(EntryResource, entry.id);
      this.$emit('on-update', this.entriesPagination.current_page);
      await this.$store.dispatch('me');
    },
    editItem(entry) {
      this.selected = entry;
      this.$bvModal.show('entryEditModal');
    },
    editPublication(entry) {
      this.selected = entry;
      this.$bvModal.show('publicationEditModal');
    },
    changeThesis(entry) {
      this.selected = entry;
      this.$bvModal.show('entryThesisModal');
    },
    getServiceType(entry) {
      const type = entry.service.type.dv;
      const subtype = entry.service.subtype ? ` - ${entry.service.subtype.fdv}` : ''
      return type + subtype;
    },
    /**
     * Нужен ли отступ сверху для типа услуги
     * @param entry
     * @returns {boolean}
     */
    isServiceTypePadding(entry) {
      return this.getServiceType(entry).split(' ').length <= 3
    },
    /**
     * Обновляет заявку (нужно для интерактивного
     * редактирования типа оплаты)
     * @param entry
     */
    async save(entry) {
      await EntryResource.save(entry);
      this.$notify({
        type: 'success',
        message: `Тип оплаты успешно изменён!`,
      });
    },
  },
}
</script>

<style>
.dashed tr {
  border-top: .2rem dashed rgba(0, 0, 0, 0.15) !important;
  border-bottom: .2rem dashed rgba(0, 0, 0, 0.15) !important;
}

.l-select-pay * {
  font-size: .8rem !important;
}

.text-calendar {
  border-left: 2px solid white;
  padding-left: .5rem;
}
</style>