<template>
  <div>
    <base-header type="default" class="pb-0 pb-md-4 mt--5 mt-md-0" />
    <div class="container-fluid mt-5 col-xl-10">
        <div class="card-header bg-transparent border-0">
          <div class="row align-items-center">
            <div class="col">
              <b-btn variant="primary" pill class="float-right" @click="editItem({})">
                + Добавить
              </b-btn>
              <h3 class="mb-0 text-white">Ведущие</h3>
            </div>
          </div>
        </div>

        <template v-if="!isLoading">
          <div class="card table-responsive table-striped">
            <base-table
                class="table align-items-center table-flush"
                tbody-classes="list bg-white border-rounded"
                :data="items"
                :thead-classes="'thead-light'"
            >
              <template slot="columns">
                <th>ФИО</th>
                <th>Описание</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td class="col-3 text-wrap py-2">{{ row.fio }}</td>
                <td class="col-6 text-wrap py-2">{{ row.description }}</td>
                <td class="text-center">
                  <a class="btn btn-link p-1 text-dark" :href="row.url" target="_blank">
                    <b-icon-box-arrow-up-right scale="0.9"/>
                  </a>
                  <b-btn tag="a" variant="link" @click="editItem(row)" class="mr-2 text-primary p-1">
                    <b-icon-pencil-fill />
                  </b-btn>
                  <b-btn tag="a" variant="link" @click="remove(row)" class="text-danger p-1">
                    <b-icon icon="trash-fill" />
                  </b-btn>
                </td>
              </template>
            </base-table>
          </div>
        </template>
        <template v-else>
          <b-skeleton-table
              class="bg-white border-rounded"
              :rows="20"
              :columns="3"
              :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <div class="d-flex justify-content-end" v-if="itemsPagination">
          <base-pagination
              :page-count="itemsPagination.last_page"
              :value="itemsPagination.current_page"
              @step="fetchData"
              class-footer="text-white"
          />
        </div>
    </div>
    <leading-forms-modal :item="selectedItem" @on-update="fetchData" />
  </div>
</template>
<script>
import LeadingResource from "../../../resources/leading";
import LeadingFormsModal from "./Leading/LeadingFormsModal";

export default {
  name: "LeadingList",
  components: {
    LeadingFormsModal
  },
  data() {
    return {
      items: [],
      selectedItem: {},
      itemsPagination: null,
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, perPage) {
      this.fetch(LeadingResource, {}, page, perPage);
    },
    async remove(item) {
      await this.deleteItem(LeadingResource, item.id);
      await this.fetchData();
    },
    editItem(item) {
      this.selectedItem = item;
      this.$bvModal.show('leadingFormsModal');
    }
  },
};
</script>