<template>
  <div class="mb-3 card-contract bg-white rounded">
    <div class="card-body p-0 card-dialog mb--1">
      <div class="p-3 mb--1">
        <div class="float-right position-absolute top-1 right-0">
<!--          <b-badge variant="grey">-->
<!--            {{ item.date }}-->
<!--          </b-badge>-->
          <contract-documents-print :item="item" />
        </div>
        <h5 class="mb-3 card-title">
          {{ item.user.fio }}
          <b-badge size="xs" class="text-dark opacity-5"> #{{ item.id }} </b-badge>
        </h5>
        <h5 class="card-subtitle font-weight-normal mb-2">
          {{ item.service.title }}
        </h5>
        <div class="float-right position-relative bottom-1 left-3">
          <!--        <h2>-->
          <!--          <i-->
          <!--            :class="`${item.service.type.icon} text-${item.service.type.color}`"-->
          <!--            class="pt-2 px-1"-->
          <!--          />-->
          <!--        </h2>-->
<!--          <contract-documents-print :item="item" />-->
        </div>

        <a class="pointer text-sm mr-3" v-if="item.file" :href="item.file.url" target="_blank">
          <b-icon icon="file-earmark" class="mt-1 mr-1" variant="dark" />
          <span class="dotted h5 font-weight-normal"> Скан подписанного договора </span>
        </a>
        <a class="text-sm" v-else>
          <b-icon icon="file-earmark-break" class="mt-1 mr-1" variant="dark" />
          <span class="h5 font-weight-normal"> Скан не загружен </span>
        </a>

        <entry-print v-b-tooltip.right="'Печать документов'" :item="row">
          <template v-slot:button>
                      <span class="dotted pointer text-sm mr-3">
                        <b-icon-printer-fill font-scale="1.1" class="mr-1" /> Документы
                      </span>
          </template>
        </entry-print>

        <user-documents-print :item="item" v-if="item.documents && item.documents.length" />

        <div
            v-if="item.comment && item.status === contractStatuses.rejected"
            class="tip mt-3 p-2 rounded bg-light text-xs text-dark"
        >
          {{ item.comment }}
        </div>

      </div>
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
import ContractDocumentsPrint from "./ContractDocumentsPrint.vue";
import EntryPrint from "../../shared/Entry/EntryPrint.vue";
import UserDocumentsPrint from "../UserDocumentsPrint.vue";

export default {
  name: 'ContractCard',
  components: {UserDocumentsPrint, EntryPrint, ContractDocumentsPrint},
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style>
.card-contract {
  position: relative;
  background: white;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ;
  color: white;
  box-shadow: 20px 20px rgba(0,0,0,.15);
  transition: all .4s ease;
}
.card-contract:hover {
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ;
  box-shadow: 10px 10px rgba(0,0,0,.25);
}

</style>