<template>
  <div class="transparent">

    <step :btn-disabled="!buttonsAvailability.document">
      <template v-slot:description>
        <h4 class="heading-small ml-3 text-white mb--3">Ваши данные по паспорту</h4>
        <div class="card card-profile mt-3 p-3">
          <profile-forms :reload="reload" :item="user" @on-done="onDocumentsDone" hide-title />
        </div>
        <h5 class="heading-small ml-3 mt-3 text-white mb--3">Паспорт</h5>
        <div class="card card-profile mt-3 p-3">
          <document-forms :reload="reload" :item="user" @on-done="onDocumentsDone" hide-title />
        </div>
      </template>
      <h5 class="heading-small ml-3 text-white mb--3">
        Документы
        <div size="sm" class="ml--3 col-10 small text-left text-transform-none" variant="secondary">
          Обратите внимание, что к загрузке допускаются только файлы с расширением .pdf, .jpg или .png
          не больше 10 Мб.
        </div>
      </h5>
      <user-documents :reload="reload" @on-done="onDocumentsDone" hide-title />
    </step>

    <h5 class="heading-small ml-3 text-white mt-3">
      Согласие на обработку персональных данных
      <div size="sm" class="ml--3 col-10 small text-left text-transform-none">
        Скачайте согласие на обработку персональных данных, подпишите его и загрузите в форму
      </div>
    </h5>
    <b-card>
      <b-row>
        <b-col lg="5" cols="12" class="pr-4 bg-secondary">
          <consent-download hide-title />
        </b-col>
        <b-col lg="7" cols="12" class="border-left">
          <consent-upload :reload="reload" @on-done="onConsentDone" hide-title />
        </b-col>
      </b-row>
    </b-card>

    <div class="text-right mt-4">
      <b-button @click="save" size="sm" :class="{ 'float-right': isManager }">
        Сохранить
      </b-button>
    </div>

<!--    <step :number="3" :current-step="currentStep" @next-step="step" :btn-disabled="!buttonsAvailability.contract">-->
<!--      <template v-slot:title> Подпишите договор </template>-->
<!--      <template v-slot:description>-->
<!--      </template>-->
<!--    </step>-->
  </div>
</template>

<script>
import Step from "./Step.vue";
import UserDocuments from "../../Profile/UserDocuments.vue";
import DocumentForms from "../Document/DocumentForms.vue";
import ProfileForms from "../Document/ProfileForms.vue";
import ConsentDownload from "../Consent/ConsentDownload.vue";
import ConsentUpload from "../Consent/ConsentUpload.vue";
import UserResource from "../../../../resources/user";
import autosaveMixin from "../../../../mixins/autosave-mixin";

export default {
  name: "StepList",
  components: {
    ConsentUpload,
    ConsentDownload,
    DocumentForms,
    ProfileForms,
    UserDocuments,
    Step,
  },
  // mixins: [
  //     autosaveMixin
  // ],
  data() {
    return {
      reload: 0,
      currentStep: 1,
      documentStep: 0,
      documentStepsTotal: 3,
      buttonsAvailability: {
        document: false,
        consent: false,
        contract: false,
      },
      steps: {
        document: 1,
        consent: 2,
        contract: 3,
      },

    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    const step = this.$store.getters.user.contract_step;
    if (Number.isInteger(step)) this.currentStep = step;
  },
  methods: {
    save() {
      this.reload++;
      this.$notify({
        type: 'dark',
        title: 'Данные сохранены!',
      })
    },
    step() {
      this.currentStep++;
      this.$store.getters.user.contract_step++;
      UserResource.update(this.$store.getters.user);
    },
    onDocumentsDone() {
      this.documentStep++;
      if (this.documentStep >= this.documentStepsTotal)
        this.buttonsAvailability.document = true;
    },
    onConsentDone() {
      this.buttonsAvailability.consent = true;
    },
  }
};
</script>

<style scoped>
.transparent .list-group-item {
  background: transparent;
  border: none;
}
</style>