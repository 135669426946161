<template>
<div>
  <base-header class="pb-5 pt-0 pt-md-5" />
  <span class="mask" />
  <div>

    <div class="mb-3 px-3 row">
      <b-col>
        <b-input-group prepend="🔎" size="sm" class="input-flat">
          <b-form-input
              placeholder="Поиск по названию или описанию"
              class="bg-transparent text-white"
              autofocus
              v-model="search"
          />
        </b-input-group>
      </b-col>
    </div>

    <div class="row">
      <div
          v-for="category in $store.getters.getDict('dict_macros_categories')"
          class="col card bg-transparent"
          :key="`category + ${category.id}`"
      >
        <div class="card-header bg-transparent pt-0 pb-2">
          <div class="card-actions float-right">
            <b-badge variant="dark" class="text-white">
              {{ getMacrosByCategory(category).length }}
            </b-badge>
          </div>
          <h5 class="card-title text-white">
            {{ category.fdv }}
          </h5>
          <h6 class="card-subtitle text-white">
            Нажмите на макрос, чтобы скопировать
          </h6>
        </div>
        <div v-for="macro in getMacrosByCategory(category)" :key="`macro + ${macro.id}`" class="mb-1">
          <macros-item :macros="macro" description-next-line btn-macros-class="bg-dark text-white" is-for-document />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MacrosItem from "./Macros/MacrosItem.vue";

export default {
  name: "Macros",
  data() {
    return {
      search: null,
    }
  },
  components: {
    MacrosItem
  },
  methods: {
    getMacrosByCategory(category) {
      let dict = this.$store.getters.getDict('dict_macroses');
      if (this.search && this.search.length)
        dict = dict.filter((item) => this.searchElm(item));
      return dict.filter((item) => (item.dict_category_id === category.id))
    },
    searchElm(elm) {
      const search = this.search.toLowerCase();
      return elm.fdv.toLowerCase().indexOf(search) !== -1 ||
          elm.description.toLowerCase().indexOf(search) !== -1;
    }
  }
}
</script>

<style scoped>

</style>