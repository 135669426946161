export default {
  methods: {
      /**
       * Статистика за текущий месяц
       * @param modelName
       * @param isFormatted
       * @returns {*}
       */
      statisticsCurrentMonth(modelName, isFormatted) {
          if (isFormatted === undefined) isFormatted = false;
          const data = this.$store.getters.getStatisticsByName(modelName);
          const lastMonth = Object.keys(data).reverse()[0];
          return isFormatted ?
              this.formatThousands(data[lastMonth]) :
              data[lastMonth];
      },

      /**
       * Статистика за последний прошедший месяц
       * @param modelName
       * @param isFormatted
       * @returns {number|*}
       */
      statisticsLastMonth(modelName, isFormatted) {
          if (isFormatted === undefined) isFormatted = false;
          const data = this.$store.getters.getStatisticsByName(modelName);
          const lastMonth = Object.keys(data).reverse()[1];
          return isFormatted ?
              this.formatThousands(data[lastMonth]) :
              data[lastMonth];
      },

      /**
       * Вычитает из статистики за текущий месяц статистику за прошедший
       * @param modelName
       * @returns {number}
       */
      statisticsGrowthInt(modelName) {
        const statisticsCurrentMonth = this.statisticsCurrentMonth(modelName);
        const statisticsLastMonth = this.statisticsLastMonth(modelName);
        return statisticsCurrentMonth - statisticsLastMonth;
      },

      /**
       * Выводит разницу между текущим и прошедшим месяцем с + или -
       * @param modelName
       * @param shouldRoundThousands
       * @returns {string}
       */
      statisticsGrowthFormatted(modelName, shouldRoundThousands) {
          if (shouldRoundThousands === undefined) shouldRoundThousands = false;
          let difference = this.statisticsGrowthInt(modelName);
          difference = shouldRoundThousands ?
              this.formatThousands(difference) :
              difference;
          return difference > 0 ? `+${difference}` : `${difference}`;
      },

      /**
       * Бутстраповский цвет для статистики за текущий/прошедший месяц
       * @param modelName
       * @returns {string}
       */
      statisticsGrowthColor(modelName) {
          let difference = this.statisticsGrowthInt(modelName);
          return difference > 0 ? `success` : `danger`;
      },

      /**
       * иконка (стрелочка вниз/вверх) для статистики за текущий/прошедший месяц
       * @param modelName
       * @returns {string}
       */
      statisticsGrowthIcon(modelName) {
          let difference = this.statisticsGrowthInt(modelName);
          return difference > 0 ? `arrow-up` : `arrow-down`;
      },

      /**
       * Делит тысячи на тысячу
       * @param number
       * @returns {number|*}
       */
      formatThousands(number) {
          return number / 1000;
      }
  }
};
