<template>
  <div class="mt-2">

    <b-form-group>
      <a-label :label="'Рубрика'" required />
      <l-select :disabled="!canBeEdited" v-model="item.dict_category_id" dict="dict_publication_categories" />
      <validation-error :errors="apiValidationErrors.dict_category_id" />
    </b-form-group>

    <b-form-group v-if="!isInternetPublication">
      <a-label :label="'Выпуск'" required />
      <l-select :disabled="!canBeEdited" v-model="item.release_id" :options="releases" />
    </b-form-group>

    <b-form-group v-if="isInternetPublication">
      <a-label :label="'Тип'" required />
      <l-select :disabled="!canBeEdited" v-model="item.type" :options="types" />
    </b-form-group>

    <b-form-group>
      <a-label :label="'Заголовок'" required />
      <b-form-input :disabled="!canBeEdited" v-model="item.title" placeholder="Заголовок" />
      <validation-error :errors="apiValidationErrors.title" />
    </b-form-group>

    <template>
      <b-form-group>
        <a-label :label="'Текст'" required />
        <template v-if="service.subtype.publication_has_file">
          <b-form-file
              v-model="item.file"
              placeholder="Выберите файл или перетащите его курсором..."
              drop-placeholder="Перетащите файл сюда..."
          />
          <a-file :file="item.file" hide-delete-btn />
        </template>
        <a-label
            v-if="service.subtype.publication_has_file && isManager"
            :label="'Текст для проверки антиплагиатом'"
            class="mt-3"
        />
        <template v-if="!service.subtype.publication_has_file || isManager">
          <ckeditor
              :disabled="!canBeEdited"
              :editor="ckEditor"
              v-model="item.text"
              @ready="onReady"
              :config="editorConfig"
          />
        </template>
        <validation-error :errors="apiValidationErrors.text" />
        <small class="mt-2 mb--2" v-if="item.text && item.text.length">
          Количество символов с учётом форматирования: <b> {{ item.text.length }} </b> <br>
          Количество символов чистого текста: <b> {{ cleanTextLength }} </b> <br>
          <b>Длина текста с учётом форматирования не должна превышать {{ maxTextLength }} символов! </b> <br>
        </small>
      </b-form-group>
    </template>

    <b-form-group>
      <a-label :label="'Приложения'" />
      <b-form-file
          multiple
          v-if="!item.id"
          v-model="item.additionalFiles"
          placeholder="Выберите файлы или перетащите их курсором..."
          drop-placeholder="Перетащите файл сюда..."
      />
      <a-file
          v-for="file in item.additionalFiles"
          :file="file"
          :key="file.id"
          @on-remove="removeFile"
      />
    </b-form-group>

    <b-form-group v-if="!item.id">
      <a-label :label="'Количество авторов'" required />
      <l-select :disabled="!canBeEdited" v-model="item.price_id" :options="prices" :allow-empty="false" />
    </b-form-group>

    <b-form-group v-if="showAuthorForm" class="mt--2">
      <b-input-group prepend="ФИО" class="mt-3">
        <b-form-input v-model="anotherAuthorFio" placeholder="Введите ФИО целиком" />
        <b-input-group-append>
          <b-btn
              variant="primary"
              class="ml--4"
              @click="addAuthor"
              v-b-tooltip="'Добавить автора'"
              :disabled="item.authors && authors.length >= (maxAuthors - 1)"
          >
            <b-icon-person-plus-fill />
          </b-btn>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <template v-if="authors.length > 0">
      <template v-if="isFormLoading">
        <b-list-group flush>
          <b-list-group-item>
            <b-skeleton class="center" width="50%"/>
          </b-list-group-item>
          <b-list-group-item>
            <b-skeleton class="center" width="80%"/>
          </b-list-group-item>
        </b-list-group>
      </template>
      <template v-else>
        <b-list-group flush>
          <b-list-group-item v-for="author in authors" :key="`author-${author.id}`">
            {{ author.fio }}
          </b-list-group-item>
        </b-list-group>
      </template>
    </template>

    <div class="text-right" v-if="canBeEdited">
      <b-button v-if="item.id" variant="primary" @click="checkAndSave" pill :disabled="!canBeSaved">
        Сохранить
      </b-button>
      <b-button v-else variant="success" @click="save" pill :disabled="!canBeSaved">
        Отправить
      </b-button>
    </div>
    <div v-if="!canBeSaved" class="text-right">
      <small class="text-danger" v-if="minTextLength > cleanTextLength">
        Минимум должно быть {{ minTextLength }} символов чистого текста!
      </small>
      <small class="text-danger" v-if="item.text && item.text.length > maxTextLength">
        Слишком много символов!
      </small>
    </div>

  </div>
</template>

<script>
import publicationMixin from "../../../mixins/publication-mixin";
import PublicationReleaseResource from "../../../resources/publication-release";
import PublicationPriceResource from "../../../resources/publication-price";
import UserResource from "../../../resources/user";

export default {
  name: "PublicationForms",
  mixins: [
      publicationMixin
  ],
  data() {
    return {
      minTextLength: 3200,
      maxTextLength: 32500,
      statuses: {
        pending: 1,
        approved: 2,
        revision: 3,
      },
      types: [
        { value: 1, label: 'Статья' },
        { value: 2, label: 'Методическая разработка' },
      ],
      publicationInternetId: 5,
      releases: [],
      prices: [],
      authors: [],
      showAuthorForm: false,
      isFormLoading: false,
      maxAuthors: 1,
      anotherAuthorFio: null,
    };
  },
  props: {
    item: {
      type: Object,
    },
    service: {
      type: Object,
    },
  },
  computed: {
    canBeEdited() {
      const isRevision = this.item.status === this.statuses.revision;
      const isPending = this.item.status === this.statuses.pending;
      return (!this.item.status || isRevision || isPending || !this.item.id) || this.isManager;
    },
    cleanTextLength() {
      if (!this.item.text) return 0;
      const cleanText = this.item.text.replace(/<\/?[^>]+(>|$)/g, "");
      return cleanText.length;
    },
    isInternetPublication() {
      return this.service.dict_subtype_id === this.publicationInternetId;
    },
    canBeSaved() {
      if (!this.item.text) return !this.isInternetPublication;
      const min = this.minTextLength <= this.cleanTextLength;
      const max = this.item.text.length <= this.maxTextLength;
      return (this.isInternetPublication && min && max) || !this.isInternetPublication;
    },
  },
  watch: {
    'item.price_id': {
      handler() {
        const chosen = this.prices.filter(item => item.id === this.item.price_id)[0];
        if (chosen.authors_num > 1) {
          this.showAuthorForm = true;
          this.maxAuthors = chosen.authors_num;
        } else {
          this.showAuthorForm = false;
          this.maxAuthors = 1;
        }
      },
    }
  },
  async mounted() {
    await this.fetchReleases();
    await this.fetchPrices();
  },
  methods: {
    async fetchReleases() {
      this.releases = await PublicationReleaseResource.fetch({
        dict_service_subtype_id: this.service.dict_subtype_id,
        is_hidden: false,
      });
    },
    async fetchPrices() {
      this.prices = await PublicationPriceResource.fetch({
        dict_service_subtype_id: this.service.dict_subtype_id,
      });
      // this.item.price_id = this.prices[0].id;
    },
    async checkAndSave() {
      this.checkStatus();
      await this.save();
    },
    /**
     * После корректировки пользователем публикации со
     * статусом "требует корректировки" устанавливает
     * публикации статус "ожидает проверки"
     */
    checkStatus() {
      if (!this.isManager) {
        switch (this.item.status) {
          case this.statuses.revision:
            this.item.status = this.statuses.pending;
            break;
        }
      }
    },
    /**
     * Удаление файла до его отправки на сервер
     */
    removeFile(file) {
      const index = this.item.additionalFiles.indexOf(file);
      this.item.additionalFiles.splice(index, 1);
    },
    /**
     * Добавление автора к публикации
     */
    async addAuthor() {
      this.isFormLoading = true;
      if (this.anotherAuthorFio && this.anotherAuthorFio.length > 0) {
        try {
          const author = await UserResource.getByFio(this.anotherAuthorFio);
          this.authors.push(author);
          this.item.authors = this.authors.map((item) => {
            return item.id
          });
          this.$notify({
            type: "success",
            message: "Автор успешно добавлен",
          });
        } catch (e) {
          this.$notify({
            type: "danger",
            message: e.response.data.message,
          });
        }
      } else {
        this.$notify({
          type: "danger",
          message: "Сначала введите ФИО!",
        });
      }
      this.isFormLoading = false;
    },
  },
}
</script>

<style>
#publicationViewModal .ck-content {
  height: 500px;
}
</style>