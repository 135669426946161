<template>
  <div>
    <base-header type="gradient-indigo" class="pb-6 pt-5 pt-md-8" />
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow pb-5">

            <!--      ТИП УСЛУГИ      -->
            <div class="card-body">
              <h3>Выберите тип:</h3>
              <div class="row icon-examples">
                <div
                    class="mr-2"
                    v-for="(type, index) in serviceTypes"
                    :key="type.id + index"
                >
                  <button
                      @click="selectType(type)"
                      :class="{'bg-purple text-white': type.id === selectedType.id}"
                      type="button"
                      :title="type.fdv"
                      class="btn-icon-clipboard p-1 p-lg-3 text-wrap"
                      data-clipboard-text="air-baloon"
                  >
                    <div>
                      <i :class="iconType(type)" />
                      <span> {{ type.fdv }} </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <!--      ПОДТИП УСЛУГИ      -->
            <div
                class="card-body mt-0 pt-0 mb-2"
                :class="{'bounce animated': animated}"
                @animationend="animated = false"
                v-if="!!selectedType.id && this.serviceSubTypes.length > 0"
            >
              <h3>Выберите подтип: </h3>
              <div class="row icon-examples">
                <div
                    class="mr-3"
                    v-for="(subtype, index) in this.serviceSubTypes"
                    :key="subtype.id + index"
                >
                  <button
                      :class="bgSubType(subtype)"
                      @click="selectSubType(subtype)"
                      type="button"
                      :title="subtype.fdv"
                      class="btn-icon-clipboard p-0 p-lg-3"
                      data-clipboard-text="air-baloon"
                  >
                    <div>
                      <i :class="iconSubType(subtype)" />
                      <span> {{ subtype.fdv }} </span>
                      <span
                          :class="{'text-white': subtype.id === selectedSubType.id}"
                          class="text-muted"
                          v-if="subtype.points > 0"
                      >
                        {{ subtype.points }} баллов
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <!--     ЗАГОЛОВОК      -->
            <div v-if="selectedType.id">
              <h2 class="ml-4 mb-4">
                {{ selectedType.fdv }}
                <span class="text-muted ml-3">
                  {{ selectedSubType.fdv }}
                </span>
                <span class="ml-3" v-if="!isManager && selectedSubType.is_publication">
                  {{ publication.price }} ₽
                </span>
                <template v-if="isManager">
                  <span
                      v-if="isTypeSelected"
                      @click="showServiceModal({ ...newService })"
                      variant="default"
                      class="btn-service ml-3 mt-2 mb-3 bg-default text-white p-2 rounded pointer"
                  >
                  + Добавить услугу
                </span>
                  <span
                      v-else
                      class="mt-2 mb-3 bg-gray text-white p-2 rounded"
                  >
                  Выберите подтип
                </span>
                </template>
              </h2>

              <!--     СПИСОК УСЛУГ      -->
              <template v-if="isManager">
                <service-table
                    v-if="isTypeSelected"
                    :type-id="selectedType.id"
                    :subtype-id="selectedSubType.id"
                    :reload="reloadTable"
                    @show-modal="showServiceModal"
                />
                <service-forms-modal
                    :item="item"
                    :type="selectedType"
                    :subtype="selectedSubType"
                    @on-update="reloadItems"
                />
              </template>
              <template v-else>
                <template v-if="!selectedSubType.is_publication">
                  <service-list
                      v-if="isTypeSelected"
                      :type-id="selectedType.id"
                      :subtype-id="selectedSubType.id"
                      :preloaded-service="preloadedService"
                  />
                </template>
                <template v-else>
                  <publication-entry
                    v-if="isTypeSelected"
                    :selected-sub-type="selectedSubType"
                    @set-publication="setPublication"
                    @reset-subtype="selectSubType({ id: null })"
                  />
                </template>
              </template>

              <template v-if="!isTypeSelected">
                <div class="card-body">
                  Выберите подтип!
                </div>
              </template>

            </div>

          </div>
        </div>
      </div>
    </div>

    <b-modal title="Услуга не найдена :(" title-class="text-lg" id="serviceNotFound" hide-footer>
      К сожалению, услуга, на которую вы перешли по ссылке, скрыта или удалена.
      Пожалуйста, закройте это окно и выберите другую услугу.
    </b-modal>

  </div>
</template>
<script>
import ServiceList from "../../user/Service/ServiceList";
import ServiceTable from "../../manager/Service/ServiceTable";
import ServiceFormsModal from "../../manager/Service/ServiceFormsModal";
import PublicationEntry from "../../user/Service/PublicationEntry";
import ServiceResource from "../../../resources/service";

export default {
  name: 'Service',
  components: {
    ServiceList,
    ServiceTable,
    ServiceFormsModal,
    PublicationEntry
  },
  data() {
    return {
      serviceSubTypes: [],
      selectedType: {},
      selectedSubType: {
        id: null,
      },
      preloadedService: {},
      item: {
        dict_type_id: null,
        dict_subtype_id: null,
        is_visible: true,
      },
      newService: {
        is_visible: 1,
        date_type: 1,
        participation_type: 1,
        // sections: [],
        // profiles: [],
        // leadings: [],
      },
      publication: {},
      reloadTable: 0,
      animated: false,
    }
  },
  computed: {
    serviceTypes() {
      return this.$store.getters.getDict('dict_service_types');
    },
    // TODO Подтипы должны зависеть от типа услуг на уровне api
    serviceSubTypesAll() {
      return this.$store.getters.getDict('dict_service_subtypes');
    },
    isTypeSelected() {
      return (!!this.selectedType.id && this.serviceSubTypes.length === 0) ||
          (!!this.selectedType.id && !!this.selectedSubType.id)
    },
  },
  async mounted() {
    await this.preloadService();
  },
  methods: {
    /**
     * Если id в ссылке не пустой, то переходит
     * к выбранной услуге
     * @returns {Promise<void>}
     */
    async preloadService() {
      const id = this.$route.params.id;
      if (id !== undefined) {
        try {
          this.preloadedService = await ServiceResource.get(id);
          if (!this.preloadedService.is_visible)
            this.$bvModal.show(`serviceNotFound`);
          const type = this.preloadedService.type;
          const subtype = this.preloadedService.subtype ?? this.selectedSubType;
          this.selectType(type);
          this.selectSubType(subtype);          
        } catch (e) {
          this.$bvModal.show(`serviceNotFound`);
        }

      }
    },
    selectType(type) {
      this.animated = true;
      this.selectedType = {};
      this.selectedSubType = {
        id: null,
      };
      this.selectedType = type;
      this.getServiceSubTypes(type.id);
    },
    getServiceSubTypes(typeId) {
      let subtypes = this.serviceSubTypesAll;
      this.serviceSubTypes = subtypes.filter(
          elm => elm.dict_service_type_id === typeId
      );
    },
    selectSubType(subtype) {
      this.selectedSubType = subtype;
    },
    iconType(type) {
      const active = this.selectedType === type ? 'text-white' : '';
      return `${type.icon} text-${type.color} ${active}`;
    },
    iconSubType(subtype) {
      const active = this.selectedSubType === subtype ? ' text-white' : '';
      const type = this.selectedType;
      return `${type.icon} text-${type.color} ${active}`;
    },
    bgSubType(subtype) {
      const type = this.selectedType;
      return this.selectedSubType === subtype ? `text-white bg-${type.color}` : '';
    },
    showServiceModal(item) {
      this.item = item;
      this.item.dict_type_id = this.selectedType.id;
      this.item.dict_subtype_id = this.selectedSubType.id;
      this.$bvModal.show('serviceFormsModal');
    },
    reloadItems() {
      this.reloadTable++;
    },
    // TODO Придумать, как вынести изящнее
    setPublication(service) {
      this.publication = service;
    }
  }
};
</script>
<style>
html {
  overflow-x: hidden;
}
.bounce {
  animation:  bounce-in-right .4s ease;
}
.btn-service:hover {
  background: var(--dark) !important;
}
</style>
